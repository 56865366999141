import { Box, Card, CardContent, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import Popup from ".";

const EcoFriendlyVehicleDialog = ({ isOpen, handleClose, model }) => {
  return (
    <Popup open={isOpen} handleClose={handleClose}>
      <Box sx={{ position: "relative", width: "500px", p: 2 }}>
        <IconButton
          aria-label="닫기"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 3,
            right: 3,
          }}
        >
          <CloseIcon
            sx={{
              width: 30,
              height: 30,
              color: "#aaa",
              cursor: "pointer",
            }}
          />
        </IconButton>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
            fontSize: "20px",
          }}
        >
          <mark>환친차 감면 적용 차량</mark>
        </Typography>
        <Typography sx={{ textAlign: "center" }}>{model}</Typography>
        <Card sx={{ lineHeight: "23px", boxShadow: "none" }}>
          <CardContent>
            이 차량은 조세특례제한법에 따라 수입신고 시 개별소비세 및 교육세의
            전부 또는 일부를 면제받은 차량입니다. 수입신고 시에 면제받은
            개별소비세 및 교육세의 전부 또는 일부를 제외한 세액으로 조회되는 점
            참고 부탁 드립니다.
          </CardContent>
        </Card>
      </Box>
    </Popup>
  );
};

export default EcoFriendlyVehicleDialog;
