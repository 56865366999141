import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import styledComponent from "styled-components";

const DialogComponent = ({ children, className, open, handleClose }) => {
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const BootstrapDialogTitle = ({ children, onClose, ...other }) => {
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {/* {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null} */}
        <Box sx={{ display: "flex" }}>
          <StyledButton onClick={onClose}>
            <CloseIcon
              sx={{
                background: "white",
                color: "#aaa",
                height: 30,
                width: 30,
                cursor: "pointer",
              }}
            />
          </StyledButton>
          <Box sx={{ flex: 1 }}>{children}</Box>
        </Box>
      </DialogTitle>
    );
  };

  return (
    <BootstrapDialog
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={className}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        차대조회
      </BootstrapDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
    </BootstrapDialog>
  );
};

const StyledButton = styledComponent.button`
    border-width: 0;
    background-color: white;
`;

export default DialogComponent;
