import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const BuyerInfo = ({ vehicleData, inquiryHandler }) => {
  //최종 업로드 Axios API
  // const [type, setType] = useState(null);
  //구매자 정보 담는 state

  const [dealerData, setDealerData] = useState({
    repName: vehicleData.dealerRepName,
    crn: vehicleData.dealerCrn,
    companyName: vehicleData.dealerCompanyName,
    ssNumber: vehicleData.dealerSSNumber,
    companyAddress: vehicleData.dealerCompanyAddress,
    salesAt: vehicleData.dealerSalesAt,
    companyTel: vehicleData.dealerCompanyTel,
    bank: vehicleData.bank,
    accntNo: vehicleData.accntNo,
  });

  const onChangeData1 = (e) => {
    setDealerData((prevState) => ({ ...prevState, repName: e.target.value }));
  };
  const onChangeData2 = (e) => {
    setDealerData((prevState) => ({
      ...prevState,
      companyName: e.target.value,
    }));
  };

  const onChangeData3 = (e) => {
    setDealerData((prevState) => ({
      ...prevState,
      companyAddress: e.target.value,
    }));
  };

  const onChangeData4 = (e) => {
    setDealerData((prevState) => ({
      ...prevState,
      salesAt: e.target.value,
    }));
  };

  const onChangeData5 = (e) => {
    setDealerData((prevState) => ({ ...prevState, crn: e.target.value }));
  };

  const onChangeData6 = (e) => {
    setDealerData((prevState) => ({
      ...prevState,
      ssNumber: e.target.value,
    }));
  };

  const onChangeData7 = (e) => {
    setDealerData((prevState) => ({
      ...prevState,
      companyTel: e.target.value,
    }));
  };
  const onChangeData8 = (e) => {
    setDealerData((prevState) => ({
      ...prevState,
      bank: e.target.value,
    }));
  };

  const onChangeData9 = (e) => {
    setDealerData((prevState) => ({
      ...prevState,
      accntNo: e.target.value,
    }));
  };

  const applicationHandler = async (e) => {
    try {
      const result = await IVISAxios.patch(
        "/indvreduction/dealer",
        new URLSearchParams({
          vin: vehicleData.vin,
          dealerData: JSON.stringify(dealerData),
        })
      );
      alert("딜러 정보 저장이 완료되었습니다.");
      // 인쿼리가 안도는 현상이있음 2021-12-31
      // 백엔드res 쳐주고 해결완료 2022-04-12
      inquiryHandler();
    } catch (error) {
      console.error(error);
    }
  };

  //신고자 정보 가져오는 핸들러 InquiryHandler에서 vehicle에조회! 동시조회 예정
  const getHandler = async (e) => {
    try {
      const result = await IVISAxios.get("/", {
        params: {
          vin: vehicleData.vinNo,
        },
      });
      setDealerData(result.data);
    } catch (e) {
      console.erorr(e);
    }
  };

  const dealerDataLoader = async (e) => {
    try {
      const result = await IVISAxios.get(`/dealer/crn/${vehicleData.crn}`);
      setDealerData(result.data);
      alert("불러오기가 완료되었습니다.");
    } catch (e) {
      console.error(e);
    }
  };

  // const typelist = ["장애인", "여객/렌터카"];
  const dg = ["1", "2", "3"];
  const dgreset = () => {
    setDealerData((prevState) => ({ ...prevState, dg: null }));
  };

  function bizNoFormatter(num, type) {
    var formatNum = "";

    try {
      if (num.length === 10) {
        if (type === 0) {
          formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-*****");
        } else {
          formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
        }
      }
    } catch (e) {
      formatNum = num;

      console.log(e);
    }

    return formatNum;
  }

  return (
    <>
      {/* <Autocomplete
        value={type}
        onChange={(event, newValue) => {
          setType(newValue);
          if (type !== "장애인") {
            dgreset();
          }
        }}
        disablePortal
        options={typelist}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="정보타입선택" />}
      /> */}
      {/* {type ? ( */}
      <div>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="대표자명"
                  onChange={onChangeData1}
                  value={dealerData.repName}
                  sx={{ width: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="상호"
                  onChange={onChangeData2}
                  value={dealerData.companyName}
                  sx={{ width: 1 }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  label="주소"
                  onChange={onChangeData3}
                  value={dealerData.companyAddress}
                  sx={{ width: 1 }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="판매 · 반출장소"
                  onChange={onChangeData4}
                  value={dealerData.salesAt}
                  sx={{ width: 1 }}
                />
              </Grid>

              {/*키값 무조건등록해야함*/}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="사업자등록번호(숫자만입력)"
                  onChange={onChangeData5}
                  value={dealerData.crn}
                  sx={{ width: 1 }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="주민등록번호"
                  onChange={onChangeData6}
                  value={dealerData.ssNumber}
                  sx={{ width: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="전화번호"
                  onChange={onChangeData7}
                  value={dealerData.companyTel}
                  sx={{ width: 1 }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="은행"
                  onChange={onChangeData8}
                  value={dealerData.bank}
                  sx={{ width: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="계좌번호"
                  onChange={onChangeData9}
                  value={dealerData.accntNo}
                  sx={{ width: 1 }}
                />
              </Grid>
            </Grid>

            <Box sx={{ display: "flex" }}>
              <Button
                sx={{ flex: 1 }}
                variant="contained"
                onClick={dealerDataLoader}
                // disabled={!type}
              >
                딜러사 정보 불러오기
              </Button>
              <Button
                sx={{ flex: 1 }}
                variant="contained"
                onClick={applicationHandler}
                // disabled={!type}
              >
                딜러사 정보 저장
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
      {/* ) : null} */}
    </>
  );
};

export default BuyerInfo;
