import React, { useState } from "react";
import TitleComponent from "../Component/Title/TitleComponent";
import Datagrid from "../Component/Datagrid/Datagrid_preinsepction";
import FileUploader from "../Component/FileUpload/FileUploader";
import { IVISAxios } from "../Modules/Axios/IVISAxios";
import SearchCalender from "../Component/Search/SearchCalendar";
import moment from "moment";
import { spinnerAtom } from "../states/Atoms/StatusAtoms";
import { useRecoilState } from "recoil";
import Button from "@mui/material/Button";
import ExcelReader from "../Component/ExcelReader/ExcelReader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/Inbox";
import JSONasXlsxPreinspection from "../Modules/JSON-to-Excel/JSONasXlsxPreinspection";
import Radio from "@mui/material/Radio";
const PreinspectionPage = () => {
  const [vehicleData, SetVehicleData] = useState([{}]);
  const [spinner, setSpinner] = useRecoilState(spinnerAtom);
  console.log(vehicleData);
  //searchCalender
  const [targetDate, setTargetDate] = useState(moment().format("YYYY-MM-DD"));
  console.log(targetDate);
  //해당날짜 Get해오는 핸들러
  const InquiryApplicationHandler = async (e) => {
    try {
      setSpinner(true);
      console.log("start");

      const result = await IVISAxios.get(`/preinspection`, {
        params: {
          targetDate: targetDate,
        },
      });
      console.log(result.data);
      SetVehicleData(result);
      console.log("end");
      setVisible(true);
    } catch (e) {
      console.log(e);
    } finally {
      //스피너
      setSpinner(false);
    }
  };
  ///////////////////////////////////////

  //엑셀데이터 추출 state
  const [data, setData] = useState([]);
  const [excelFileName, setExcelFileName] = useState("");
  console.log(excelFileName);
  console.log(data.filter((x) => x["Invoice number"]));

  //엑셀 데이터 보내는 API
  const ExcelPostHandler = async (e) => {
    console.log("엑셀 데이터 보내기 실행");
    try {
      const result = await IVISAxios.post(
        `/preinspection`,
        new URLSearchParams({
          data: JSON.stringify(data.filter((x) => x["Invoice number"])),
          fileName: excelFileName,
          targetDate: targetDate,
        })
      );
      console.log(result);
      console.log("엑셀 데이터 보내기 완료");
      alert("엑셀 데이터 등록 완료");
      InquiryApplicationHandler();
    } catch (e) {
      console.log(e);
    }
  };

  const [selectedData, setSelectedData] = useState([]);
  const SelectionChangedHandler = async (e) => {
    try {
      const selectedID = e.target.id ? e.target.id : e.target.parentNode.id;
      console.log(selectedID);
      setSelectedData([]);
      const result = await IVISAxios.get(`/preinspection/${selectedID}`);
      if (result) {
        setSelectedData(result.data);
        console.log(result.data);
      } else {
        setSelectedData([]);
      }
    } catch (e) {
      console.log(e);
    }
  };
  //라디오박스
  const [visible, setVisible] = useState(false);
  const [radioType, setRadioType] = useState("인증");
  const handleChangeRadio = (event) => {
    setRadioType(event.target.value);
    InquiryApplicationHandler();
  };
  console.log("인증상태 :", radioType);

  return (
    <>
      <TitleComponent Title="보수작업" type="2" />

      <div className="container">
        <br />
        <SearchCalender
          date={targetDate}
          setDate={setTargetDate}
          InquiryApplicationHandler={InquiryApplicationHandler}
        />
        {vehicleData &&
          vehicleData.data &&
          vehicleData.data.map((x) => {
            return (
              <ListItemButton
                id={x.id}
                key={x.id}
                onClick={SelectionChangedHandler}
              >
                <ListItemIcon>
                  <InboxIcon id={x.id} />
                </ListItemIcon>
                <ListItemText id={x.id}>{x.fileName}</ListItemText>
              </ListItemButton>
            );
          })}
        <ExcelReader
          setData={setData}
          setExcelFileName={setExcelFileName}
          ExcelPostHandler={ExcelPostHandler}
        />
        <Button onClick={ExcelPostHandler}>엑셀 등록</Button>

        {visible && (
          <div>
            <Radio
              checked={radioType === "인증"}
              onChange={handleChangeRadio}
              value="인증"
            />
            인증대상만
            <Radio
              checked={radioType === "전체"}
              onChange={handleChangeRadio}
              value="전체"
            />
            전체
          </div>
        )}
        <JSONasXlsxPreinspection
          vehicleData={selectedData}
          radioType={radioType}
        />
        <Datagrid selectedData={selectedData} radioType={radioType} />
      </div>
    </>
  );
};

export default PreinspectionPage;
