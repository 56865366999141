import React from "react";
import Dialog from "@mui/material/Dialog";

const Popup = ({ props, children, open, handleClose, className }) => {
  return (
    <Dialog
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className={className}
      sx={{ borderRadius: 3 }}
    >
      {children}
    </Dialog>
  );
};

export default Popup;
