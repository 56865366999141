import React from "react";
import PasswordForm from "../Component/Password/PasswordForm";

//라우팅 페이지
const PasswordPage = () => {
  return (
    <div>
      <br /> <br /> <br />
      <PasswordForm />
    </div>
  );
};

export default PasswordPage;
