import * as React from "react";
import Stack from "@mui/material/Stack";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useRecoilState, useSetRecoilState } from "recoil";
import { snackbarAtom } from "../../states/Atoms/StatusAtoms";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// TODO: 231010 텍스트, 성공여부 추가 (개선 필요)
const CustomizedSnackbars = ({
  snackOpen,
  setSnackOpen,
  duration,
  severity,
  text,
}) => {
  // const handleClick = () => {
  //   setSnackOpen(true);
  // };
  const [snackbarState, setSnackbarState] = useRecoilState(snackbarAtom);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, isOpen: false });
    // setSnackOpen(false);
  };
  //

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {/* <Button variant="outlined" onClick={handleClick}>
        Open success snackbar
      </Button> */}
      <Snackbar
        open={snackOpen}
        autoHideDuration={duration}
        onClose={handleClose}
        sx={{
          whiteSpace: "pre-line",
        }}
      >
        {/* serverity : "error", "warning", "info", "success" */}
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {text || "This is a success message!"}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default CustomizedSnackbars;
