import React, { useEffect, useState } from "react";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import TextFiled from "@mui/material/TextField";
import styled from "styled-components";
const UpdatePage = () => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [text, setText] = useState("");

  const history = useHistory();
  const onChangeTitle = (e) => {
    setTitle(e.target.value);
  };

  const onChangeAuthor = (e) => {
    setAuthor(e.target.value);
  };
  const onChangeText = (e) => {
    setText(e.target.value);
  };

  const UpdateHandler = async () => {
    try {
      const result = await IVISAxios.patch(
        "/Board",
        new URLSearchParams({
          title: title,
          author: author,
          text: text,
        })
      );
      console.log(result);
      history.push("/BoardPage");
    } catch (e) {
      console.log(e, "수정 실패");
    }
  };

  const DeleteHandler = async () => {
    try {
      const result = await IVISAxios.delete("/Board", new URLSearchParams({}));
      console.log(result);
      history.push("/BoardPage");
    } catch (e) {
      console.log(e, "삭제 실패");
    }
  };
  return (
    <LayoutBox>
      <h3>글쓰기 페이지</h3>
      제목 : <TextFiled onChange={onChangeTitle} />
      <br />
      작성자 : <TextFiled onChange={onChangeAuthor} />
      <br />
      내용 : <TextFiled onChange={onChangeText} />
      <br />
      <a href="/BoardPage">
        <Button>이전</Button>
      </a>
      <Button onClick={UpdateHandler}>게시글 수정</Button>
      <Button onClick={DeleteHandler}>게시글 삭제</Button>
    </LayoutBox>
  );
};

export default UpdatePage;

const LayoutBox = styled.div`
  margin-top: 50px;
  margin-left: 200px;
  margin-right: 200px;
`;
