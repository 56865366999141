import moment from "moment";
import { atom } from "recoil";

export const typeState = atom({
  key: "#typeState",
  default: "A",
});

export const startState = atom({
  key: "#startDateState",
  default: moment().subtract("1", "M").format("YYYY-MM-DD"),
});

export const finishState = atom({
  key: "#endDateState",
  default: moment().format("YYYY-MM-DD"),
});

export const vehiclesState = atom({
  key: "#vehiclesState",
  default: [],
});

export const crnState = atom({
  key: "#delaerCompanyCode",
  default: "all",
});
