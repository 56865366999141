import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import Box from "@mui/material/Box";
import moment from "moment";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import Tooltip from "@mui/material/Tooltip";
import Styled from "styled-components";

import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import { useRecoilValue } from "recoil";
import { authoritiesAtom } from "../../states/Atoms/AuthAtoms";
import BMW_LOGO from "../../img/logo/bmw-logo.png";
import MINI_LOGO from "../../img/logo/mini-logo.png";
import RollsRoyce_LOGO from "../../img/logo/Rolls-Royce-logo.png";
import { CRN_MAP } from "../../utils/crn-mapper";

const DataGridComponent = ({
  vehicleData,
  handleOpen,
  setVinNumber,
  pageSize,
}) => {
  const [test, setRefresher] = useState(null);
  const authState = useRecoilValue(authoritiesAtom);

  //환급신청서 다운로드
  const DownLoadREF = async (e, vin) => {
    try {
      const result = await IVISAxios.get(`/attached`, {
        params: {
          vin: vin,
          type: "ar",
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${vin}_환급신청서.pdf`);
      document.body.appendChild(link);
      link.click();

      console.log(result);
    } catch (e) {
      console.log(e);
    }
  };

  //반출신청서 다운로드
  const DonwnLoadDF = async (e, vin) => {
    try {
      const result = await IVISAxios.get(`/attached`, {
        params: {
          vin: vin,
          type: "ac",
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${vin}_반출신청서.pdf`);
      document.body.appendChild(link);
      link.click();

      console.log(result);
    } catch (e) {
      console.log(e);
    }
  };

  //환급신청서 컨펌
  const ConfirmHandlerref = async (e, vin) => {
    try {
      const result = await IVISAxios.patch(
        "/pdfref",
        new URLSearchParams({
          vin: vin,
        })
      );

      setRefresher(new Date());
      if (result) {
        //업데이트
        const updateResult = await inquiryAttachedFiles(vin);
        if (updateResult) {
          vehicleData
            .filter((x) => x.vin === vin)[0]
            .attacheds.filter((x) => x.type === "ar")[0].confirmAt =
            updateResult["ar"].confirmAt;
          vehicleData
            .filter((x) => x.vin === vin)[0]
            .attacheds.filter((x) => x.type === "ar")[0].confirmBy =
            updateResult["ar"].confirmBy;
        }
      }
      console.log(result);
      console.log(result);
    } catch (e) {
      console.log(e);
    }
  };

  //반출신청서 컨펌
  const ConfirmHandlerdf = async (e, vin) => {
    try {
      const result = await IVISAxios.patch(
        "/pdfdfco",
        new URLSearchParams({
          vin: vin,
        })
      );
      setRefresher(new Date());
      if (result) {
        //업데이트
        const updateResult = await inquiryAttachedFiles(vin);
        if (updateResult) {
          vehicleData
            .filter((x) => x.vin === vin)[0]
            .attacheds.filter((x) => x.type === "ac")[0].confirmAt =
            updateResult["ac"].confirmAt;
          vehicleData
            .filter((x) => x.vin === vin)[0]
            .attacheds.filter((x) => x.type === "ac")[0].confirmBy =
            updateResult["ac"].confirmBy;
        }
      }
      console.log(result);
    } catch (e) {
      console.log(e);
    }
  };
  const inquiryAttachedFiles = async (vin) => {
    try {
      const result = await IVISAxios.get("/attached", {
        params: { vin: vin },
      });
      if (result.data.success) {
        return result.data.result;
      } else return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const clickHandler = (e) => {
    setVinNumber(e.target.innerText);
    handleOpen();
  };
  //고정 헤더 2021-10-29
  const columns = [
    {
      field: "brand",
      headerName: "",
      width: 60,
      renderCell: (params) => (
        <>
          {params.value === "BMW" ? (
            <div className="width100 flex-row-center">
              <LogoSize src={BMW_LOGO} />
            </div>
          ) : params.value === "MINI" ? (
            <div className="width100 flex-row-center">
              <LogoSize src={MINI_LOGO} />
            </div>
          ) : params.value === "RollsRoyce" ? (
            <div className="width100 flex-row-center">
              <LogoSize src={RollsRoyce_LOGO} />
            </div>
          ) : null}
        </>
      ),
    },
    {
      field: "vin",
      headerName: "차대번호",
      width: 180,
      renderCell: (params) => (
        // <a href={`/search/${params.value}`}>{params.value}</a>
        <SpanPointer onClick={clickHandler}>{params.value}</SpanPointer>
      ),
    },
    {
      field: "type",
      headerName: "유형",
      width: 60,
    },
    {
      field: "createdAt",
      headerName: "신청",
      width: 90,
      renderCell: (params) => (
        <div>{renderDateByMoment(params.value, "MM/DD")}</div>
      ),
    },
    {
      field: "appliedAt",
      headerName: "접수",
      width: 90,
      renderCell: (params) => (
        <div>{renderDateByMoment(params.value, "MM/DD")}</div>
      ),
    },
    {
      field: "approvedAt",
      headerName: "승인",
      width: 90,
      renderCell: (params) => (
        <div>{renderDateByMoment(params.value, "MM/DD")}</div>
      ),
    },
    {
      field: "paidAt",
      headerName: "지급",
      width: 90,
      renderCell: (params) => (
        <div>{renderDateByMoment(params.value, "MM/DD")}</div>
      ),
    },
    {
      field: "crn",
      headerName: "딜러사",
      width: 70,

      renderCell: (params) => (
        <Box sx={{ flex: 1, textAlign: "center" }}>
          {CRN_MAP.get(params.value)}
        </Box>
      ),
    },
    {
      field: "deadLine",
      headerName: "마감기한",
      width: 110,
      renderCell: (params) =>
        params.value ? (
          <div>{moment(params.value).format("YYYY/MM/DD")}</div>
        ) : null,
    },
    {
      field: "totalRefund",
      headerName: "총 환급금",
      width: 110,
      renderCell: (params) => (
        <Box sx={{ flex: 1, textAlign: "right" }}>
          <span>{params.value.toLocaleString()}</span>
        </Box>
      ),
    },
    {
      field: "comment",
      headerName: "comment",
      width: 100,
      renderCell: (params) => {
        return params.row.applhistories &&
          params.row.applhistories.filter((x) =>
            authState.includes("AONEBMW")
              ? x.writtenBy === "D"
              : authState.includes("BMWDEALER")
              ? x.writtenBy === "A"
              : false
          ).length > 0 ? (
          <Box sx={{ flex: 1, textAlign: "center" }}>
            <DatagridBox>
              <FiberNewIcon />
            </DatagridBox>
          </Box>
        ) : null;
      },
    },
    {
      field: "PDF",
      headerName: "환급신청서",
      width: 100,
      renderCell: (params) => {
        const attached = params.row.attacheds;
        const filtered = attached
          ? attached.filter((x) => x.type === "ar")
          : null;
        return attached &&
          attached.filter((x) => x.type === "ar").length > 0 ? (
          <Box sx={{ flex: 1, textAlign: "center" }}>
            <IconButton onClick={(e) => DownLoadREF(e, params.row.vin)}>
              <DownloadIcon />
            </IconButton>
            {filtered[0].confirmAt ? (
              <Tooltip
                title={moment(filtered[0].confirmAt).format("YYYY/MM/DD HH:mm")}
              >
                <IconButton sx={{ color: "#0a0" }}>
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            ) : (
              //  <Tooltip title={filtered[0].confirmAt}> g</Tooltip>

              <IconButton
                sx={{ color: "#ddd" }}
                onClick={(e) => ConfirmHandlerref(e, params.row.vin)}
                disabled={authState.includes("AONEBMW")}
              >
                <CheckIcon />
              </IconButton>
            )}
          </Box>
        ) : null;
      },
    },
    {
      field: "PDF2",
      headerName: "반출신청서",
      width: 100,
      renderCell: (params) => {
        const attached = params.row.attacheds;
        const filtered = attached
          ? attached.filter((x) => x.type === "ac")
          : null;
        return filtered && filtered.length > 0 ? (
          <Box sx={{ flex: 1, textAlign: "center" }}>
            <IconButton onClick={(e) => DonwnLoadDF(e, params.row.vin)}>
              <DownloadIcon />
            </IconButton>

            {filtered[0].confirmAt ? (
              <Tooltip
                title={moment(filtered[0].confirmAt).format("YYYY/MM/DD HH:mm")}
              >
                <IconButton sx={{ color: "#0a0" }}>
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton
                sx={{ color: "#ddd" }}
                onClick={(e) => ConfirmHandlerdf(e, params.row.vin)}
                disabled={authState.includes("AONEBMW")}
              >
                <CheckIcon />
              </IconButton>
            )}
          </Box>
        ) : null;
      },
    },
  ];
  const renderDateByMoment = (date, format) => {
    return date ? moment(date).format(format) : "";
  };

  return (
    <DataGrid
      autoHeight
      columns={columns}
      rows={vehicleData}
      pageSize={pageSize}
      rowsPerPageOptions={[pageSize]}
      hideFooterSelectedRowCount
      hideFooterPagination={vehicleData.length === 0}
    />
  );
};
export default DataGridComponent;
const DatagridBox = Styled.div`
margin-top: 2vh
`;
const SpanPointer = Styled.span`
cursor: pointer
`;
const LogoSize = Styled.img`
  width: 50%;
  align-self: center;
`;
