export const CRN_MAP = new Map([
  ["1398125991", "BV"],
  ["1208150012", "KL"],
  ["1138803053", "KMG1"],
  ["8338702544", "KMG"],
  ["2148728379", "HD"],
  ["4188108269", "NT"],
  ["3018144690", "SCL"],
  ["6098142811", "DT"],
  ["6218101557", "DS"],
]);
