import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import DealerInfo from "../AONE/DealerInfo";
const DealerBox = ({ vehicleData, inquiryHandler }) => {
  return (
    <Box sx={{}}>
      <Card variant="outlined" sx={{ borderRadius: 3 }}>
        <CardHeader title="딜러사정보" sx={{ backgroundColor: "#eee" }} />

        <CardContent>
          <DealerInfo
            vehicleData={vehicleData}
            inquiryHandler={inquiryHandler}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
export default DealerBox;
