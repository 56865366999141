import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import styled from "styled-components";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useRecoilValue } from "recoil";
import { authoritiesAtom } from "../../states/Atoms/AuthAtoms";
import moment from "moment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import { Typography } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DeleteIcon from "@mui/icons-material/Delete";
const PDFComponent = ({
  title,
  isUploaded,
  type,
  params,
  url,
  inquiryHandler,
  vehicleData,
}) => {
  const authState = useRecoilValue(authoritiesAtom);
  // const [alert, setAlert] = useState({});
  // isUploaded && isUploaded[type] && console.log(isUploaded[type].confirmAt);
  //신청서 생성
  const CreateHandler = async (e) => {
    try {
      if (
        vehicleData.type &&
        vehicleData.buyerName &&
        vehicleData.buyerAddress &&
        // vehicleData.buyerCRN &&  장애인은 개인이라 회사명이없다고해서 삭제 220615
        // vehicleData.buyerCompanyName && 장애인은 개인이라 회사명이없다고해서 삭제 220615
        vehicleData.crn &&
        vehicleData.taxOffice &&
        vehicleData.vehicleRegNo
      ) {
        const result = await IVISAxios.post(url, params);
        inquiryHandler();
      } else {
        alert("필수 정보를 확인해주세요");
      }
      return;
    } catch (e) {
      console.log(e);
    }
  };

  //신청서 삭제
  const DeleteHandler = async (e) => {
    try {
      const result = await IVISAxios.delete(url, {
        params: {
          vin: vehicleData.vin,
        },
      });
      inquiryHandler();
    } catch (e) {
      console.log(e);
    }
  };

  //신청서 다운로드
  const DownLoadHandler = async (e) => {
    try {
      const result = await IVISAxios.get(`/attached`, {
        params: {
          vin: vehicleData.vin,
          type: type,
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", isUploaded[type].fileName);
      document.body.appendChild(link);
      link.click();
      inquiryHandler();
    } catch (e) {
      console.log(e);
    }
  };

  //신청서 확인 AONE / BMWKOREA
  const PDFConfirmHandler = async (e) => {
    try {
      const result = await IVISAxios.patch(
        url,
        new URLSearchParams({
          vin: vehicleData.vin,
        })
      );

      inquiryHandler();
    } catch (e) {
      console.log(e);
    }
  };

  //신청서 확인 취소 DEALER
  const PDFConfirmCancel = async () => {
    try {
      const result = await IVISAxios.patch(
        `${url}/restore`,
        new URLSearchParams({
          vin: vehicleData.vin,
        })
      );

      inquiryHandler();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        p: 2,
        borderRadius: "10px",
        background: "#ffffff",
        boxShadow: "14px 14px 23px #e6e6e6, -14px -14px 23px #ffffff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          background: "#f8f8f8",
          borderRadius: 2,
          p: 1,
        }}
      >
        <Box sx={{ display: "flex" }}>
          {title}
          {isUploaded && isUploaded[type] && isUploaded[type].confirmAt && (
            <Tooltip
              title={
                moment(isUploaded[type].confirmAt).format("YYYY/MM/DD HH:mm") +
                " " +
                isUploaded[type].confirmBy
              }
            >
              <CheckIcon sx={{ color: "#0a0", width: 15, height: 15 }} />
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Box
            sx={{
              background: "#eee",
              textAlign: "center",
              p: 1,
              flex: 1,
            }}
          >
            발급
          </Box>

          <Box sx={{ display: "flex", flex: 1 }}>
            {authState.includes("AONEBMW") &&
              !(isUploaded && isUploaded[type]) && (
                // <IconButton onClick={CreateHandler} sx={{ flex: 1 }}>
                //   <NoteAddIcon fontSize="large" sx={{ color: "#5c5" }} />
                // </IconButton>
                <Button
                  variant="contained"
                  color="success"
                  onClick={CreateHandler}
                  sx={{ flex: 1 }}
                >
                  발급
                </Button>
              )}
            {isUploaded && isUploaded[type] && (
              // <IconButton onClick={DeleteHandler} sx={{ flex: 1 }}>
              //   <DeleteIcon fontSize="large" sx={{ color: "#c55" }} />
              // </IconButton>
              <Button
                sx={{ flex: 1 }}
                variant="contained"
                color="error"
                onClick={DeleteHandler}
              // disabled={
              //   isUploaded[type].confirmAt || !authState.includes("AONEBMW")
              // }
              >
                발급취소
              </Button>
            )}
          </Box>
          <Box sx={{ textAlign: "center", flex: 1 }}>
            <Typography variant="caption">
              {isUploaded && isUploaded[type] ? (
                <>
                  {moment(isUploaded[type].createdAt).format(
                    "YYYY/MM/DD HH:mm"
                  )}
                </>
              ) : (
                "-"
              )}
            </Typography>
            {/* 
        1203 회의 이후 삭제요청 aone 11항목
        
        
        최종확인 시간 :
        {isUploaded && isUploaded[type] && isUploaded[type].confirmAt} <br />
        BMW Korea 확인시간 :
        {isUploaded && isUploaded[type] && isUploaded[type].confirmAt} */}
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowForwardIcon />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Box sx={{ background: "#eee", textAlign: "center", p: 1 }}>열람</Box>
          <Button
            variant="contained"
            color="success"
            onClick={DownLoadHandler}
            disabled={!isUploaded || !isUploaded[type]}
          >
            다운로드
          </Button>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ArrowForwardIcon />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Box sx={{ background: "#eee", textAlign: "center", p: 1 }}>
            딜러사승인
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isUploaded && isUploaded[type] && isUploaded[type].confirmAt && (
              <Button
                variant="contained"
                color="error"
                sx={{ flex: 1 }}
                onClick={PDFConfirmCancel}
                disabled={!authState.includes("BMWDEALER")}
              >
                승인취소
              </Button>
            )}
            {(!isUploaded ||
              !isUploaded[type] ||
              !isUploaded[type].confirmAt) && (
                <Button
                  variant="contained"
                  sx={{ flex: 1 }}
                  disabled={
                    !(isUploaded && isUploaded[type]) ||
                    !authState.includes("BMWDEALER")
                  }
                  onClick={PDFConfirmHandler}
                >
                  승인
                  {/* <CheckCircleOutlineIcon /> */}
                </Button>
              )}
          </Box>

          <Box sx={{ textAlign: "center" }}>
            <Typography variant="caption">
              {isUploaded && isUploaded[type] && isUploaded[type].confirmAt ? (
                <>
                  {moment(isUploaded[type].confirmAt).format(
                    "YYYY/MM/DD HH:mm"
                  )}
                </>
              ) : (
                "-"
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* 2021/11/25 18:20 발급일시에 현재시각 표시되는 오류 createAt -> createdAt으로 수정 */}
    </Box>
  );
};

export default PDFComponent;
