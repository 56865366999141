import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MUITableNoHeader from "../Table/MUITableNoHeader";
import FileUploader from "../FileUpload/FileUploader";
import IndvFileUploader from "../FileUpload/IndvFileUploader";
import styled from "styled-components";
import ActivityCheck from "../History/ActivityCheck";
import ActivityHistory from "../History/ActivityHistory";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import BuyerInfo from "../AONE/BuyerInfo";
import PDFComponent from "../pdf/PDFComponent";
import StepBarTester from "../Progress/StepBarTester";
import StepBar from "../Progress/StepBar";
import Stepp from "../Progress/Stepp";
import { authoritiesAtom } from "../../states/Atoms/AuthAtoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { spinnerAtom, toastAtom } from "../../states/Atoms/StatusAtoms";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import DealerBox from "../AONE/DealerBox";
import BuyerBox from "../AONE/BuyerBox";
import RepBox from "../AONE/RepBox";
import AppliedStatusTable from "../Table/AppliedStatusTable";
import AppliedType from "./AppliedType";

const refundInfo = {
  type: "신청타입",
  vin: "차대번호",
  importNumber: "수입신고번호",
  appNumber: "환급신청번호",
  expectedAppliedAt: "접수일",
  receivedAt: "딜러->AONE",
  amount: "환급신청액",
  bank: "은행",
  accntNo: "계좌번호",
  dg: "장애등급",
  buyerName: "대표자명",
  buyerCompanyName: "회사명",
  buyerAddress: "주소",
  buyerPlace: "판매 반출장소",
  taxOffice: "세무서",
  buyerCRN: "사업자등록번호",
  buyerSSNumber: "주민등록번호",
  buyerTel: "전화번호",
  salesDate: "자동차등록연월일",
  vehicleRegNo: "자동차등록번호",
  dealerCompanyName: "딜러사명",
  dealerRepName: "딜러사대표자",
  dealerCompanyTel: "딜러사전화번호",
  dealerCompanyAddress: "딜러사주소",
  dealerCrn: "딜러사사업자번호",
  dealerSSNumber: "딜러주민등록번호",
  dealerSalesAt: "판매 · 반출장소",
};

const AppliedComponent = ({
  vehicleData,
  inquiryAttachedFiles,
  isUploaded,
  inquiryHandler,
  HistoryHandler,
  active,
}) => {
  const authState = useRecoilValue(authoritiesAtom);

  const [isConfirmed, setIsConfirmed] = useState(false);
  useEffect(() => {}, []);

  const [snackState, setSnackState] = useRecoilState(toastAtom);

  const [salesData, setSalesData] = useState({
    salesDate: "",
  });

  //BMW Data담는 state
  const [importerData, setImporterData] = useState({
    importerRep: "",
    importerCrn: "",
    importerName: "",
    importerAddr: "",
    bwCode: "",
  });
  const clientgetEffect = async (e) => {
    try {
      const result = await IVISAxios.get(`/client/2118608983`);
      setImporterData(result.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setSalesData({
      salesDate: vehicleData.salesDate,
    });
    clientgetEffect();
  }, [vehicleData]);

  const onChangeDeadline = (e) => {
    setSalesData((prevState) => ({ ...prevState, salesDate: e.target.value }));
    //setDeadline(e.target.value);
  };
  const DeadlineHandler = async (e) => {
    try {
      const result = await IVISAxios.patch(
        "/indvreduction/salesdate",
        new URLSearchParams({
          vin: vehicleData.vin,
          salesDate: salesData.salesDate,
        })
      );
      alert("등록이 완료되었습니다.");
      inquiryHandler();
    } catch (error) {
      console.error(error);
    }
  };
  const SnackTest = () => {
    setSnackState(true);
  };

  // const handleApplyCancel = async (vin) => {
  //   try {
  //     const res = await IVISAxios.delete(`/indvreduction/${vin}`);
  //     console.log("cancel res data : ", res.data);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
      <>
        {isConfirmed || vehicleData.status ? (
          <></>
        ) : (
          <Box sx={{ display: "flex", gap: "10px" }}>
            {/* 2021/12/13 신청 후 타입 변경하는 기능을 없앰 */}
            {/* 230901-230904 타입 변경 기능 및 신청취소 기능 추가 */}
            <AppliedType
              vehicleData={vehicleData}
              inquiryHandler={inquiryHandler}
              buttonname="신청타입변경"
            />
          </Box>
        )}
      </>
      <Grid
        container
        spacing={2}
        sx={{ background: "#eef", paddingBottom: 2, paddingRight: 2 }}
      >
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flex: 1 }}>
            {/* <div>판매일자로 부터 6개월이 속하는 마지막 날까지</div> */}
            {/* {authState.includes("BMWDEALER") && ( */}
            <Box sx={{ background: "#88a", p: 2, color: "#fff" }}>등록일자</Box>
            <TextField
              id="date"
              type="date"
              sx={{ width: 220, flex: 1, alignSelf: "center" }}
              onChange={onChangeDeadline}
              value={salesData.salesDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button variant="contained" onClick={DeadlineHandler}>
              저장
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {/*<TextField
                    id="date"
                    label="마감일자"
                    type="date"
                    value={vehicleData.deadline}
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
           />*/}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Box sx={{ background: "#88a", p: 2, color: "#fff" }}>통합파일</Box>
            <FileUploader
              type={0}
              vin={vehicleData ? vehicleData.vin : null}
              inquiryAttachedFiles={inquiryAttachedFiles}
              isUploaded={isUploaded}
              isConfirmed={isConfirmed}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 5 }}
          >
            <Box
              sx={{
                borderRadius: "50px",
                background: "#ffffff",
                boxShadow:
                  "20px 20px 60px #d9d9d9,             -20px -20px 60px #ffffff",
              }}
            >
              <Card variant="outlined" sx={{ borderRadius: 3 }}>
                <CardHeader
                  title="환급진행상황"
                  sx={{ backgroundColor: "#eee" }}
                />

                <CardContent>
                  {/* <StepBarTester /> */}
                  <Stepp vehicleData={vehicleData} />
                  {/* <StepBar vehicleData={vehicleData} /> */}
                  <AppliedStatusTable data={vehicleData} />
                </CardContent>
              </Card>
            </Box>

            <Box
              sx={{
                borderRadius: "50px",
                background: "#ffffff",
                boxShadow:
                  "20px 20px 60px #d9d9d9,             -20px -20px 60px #ffffff",
              }}
            >
              <Card variant="outlined" sx={{ borderRadius: 3 }}>
                <CardHeader
                  title="환급신청정보"
                  sx={{ backgroundColor: "#eee" }}
                />
                <CardContent>
                  <MUITableNoHeader source={vehicleData} match={refundInfo} />
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column", //플렉스 방향
              flex: 1,
              gap: 5,
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                borderRadius: "50px",
                background: "#ffffff",
                boxShadow:
                  "20px 20px 60px #d9d9d9,             -20px -20px 60px #ffffff",
              }}
            >
              <Card variant="outlined" sx={{ borderRadius: 3 }}>
                <CardHeader title="신청서" sx={{ backgroundColor: "#eee" }} />

                <CardContent>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 3 }}
                  >
                    <PDFComponent
                      title="개별소비세 환급 신청서"
                      isUploaded={isUploaded}
                      type="ar"
                      url="/pdfref"
                      inquiryHandler={inquiryHandler}
                      vehicleData={vehicleData}
                      params={
                        vehicleData
                          ? new URLSearchParams({
                              accntNo: vehicleData.accntNo,
                              totalRefund: vehicleData.totalRefund,
                              bank: vehicleData.bank,
                              importNumber: vehicleData.importNumber,
                              vin: vehicleData.vin,
                              appliedAt: vehicleData.appliedAt,
                              desc: vehicleData.desc,
                              qty: vehicleData.qty,
                              crn: vehicleData.crn,
                              totalTaxationKRW: vehicleData.totalTaxationKRW,
                              gs: vehicleData.gs,
                              indv: vehicleData.indv,
                              edu: vehicleData.edu,
                              reducedIndv: vehicleData.reducedIndv,
                              reducedEdu: vehicleData.reducedEdu,
                              salesDate: vehicleData.salesDate,
                              repName: vehicleData.dealerRepName,
                              companyName: vehicleData.dealerCompanyName,
                              companyAddr: vehicleData.dealerCompanyAddress,
                              aoneCrn: vehicleData.dealerCrn,
                              telNo: vehicleData.dealerCompanyTel,
                              //개별소비세 환급신청서 제조(판매)장소재지 삭제 빈칸 요청.
                              taxOffice: vehicleData.taxOffice,

                              //고정 데이터
                              importerRep: importerData.importerRep,
                              importerCrn: importerData.importerCrn,
                              importerName: importerData.importerName,
                              importerAddr: importerData.importerAddr,
                              bwCode: importerData.bwCode,

                              appNumber: vehicleData.appNumber,
                              expectedAppliedAt: vehicleData.expectedAppliedAt,

                              modelYear: vehicleData.modelYear,
                              dpVolume: vehicleData.dpVolume,
                            })
                          : null
                      }
                    />

                    <IndvFileUploader
                      title="BMW Korea 개별소비세 환급 신청서"
                      type="arb"
                      isUploaded={isUploaded}
                      isConfirmed={isConfirmed}
                      deleteAuthority="AONEBMW"
                      vin={vehicleData ? vehicleData.vin : null}
                      inquiryAttachedFiles={inquiryAttachedFiles}
                    />

                    <PDFComponent
                      title="승용차 개별소비세 면세 반출 신고서"
                      isUploaded={isUploaded}
                      type="ac"
                      params={
                        vehicleData
                          ? new URLSearchParams({
                              appNumber: vehicleData.appNumber,
                              expectedAppliedAt: vehicleData.expectedAppliedAt,
                              totalRefund: vehicleData.totalRefund,
                              vin: vehicleData.vin,
                              appliedAt: vehicleData.appliedAt,
                              qty: vehicleData.qty,
                              crn: vehicleData.crn,
                              //반출연월일 : carryOutDate: vehicleData.carryOutDate,
                              //반입ad
                              buyerName: vehicleData.buyerName,
                              buyerCompanyName: vehicleData.buyerCompanyName,
                              buyerAddress: vehicleData.buyerAddress,
                              buyerPlace: vehicleData.buyerPlace,
                              buyerCRN: vehicleData.buyerCRN,
                              buyerSSNumber: vehicleData.buyerSSNumber,
                              buyerTel: vehicleData.buyerTel,
                              registeredAt: vehicleData.registeredAt,
                              salesDate: vehicleData.salesDate,
                              //////////////////////////////////////////
                              modelName: vehicleData.desc,
                              cc: vehicleData.cc,
                              price: vehicleData.price,
                              taxRate: vehicleData.taxRate,
                              vehicleRegNo: vehicleData.vehicleRegNo,
                              modelYear: vehicleData.modelYear,
                              dg: vehicleData.dg ? vehicleData.dg : "",
                              gs: vehicleData.gs,
                              bwCode: vehicleData.dealerSalesAt,
                              repName: vehicleData.dealerRepName,
                              companyName: vehicleData.dealerCompanyName,
                              companyAddr: vehicleData.dealerCompanyAddress,
                              aoneCrn: vehicleData.dealerCrn,
                              telNo: vehicleData.dealerCompanyTel,
                              reducedIndv: vehicleData.reducedIndv,
                              reducedEdu: vehicleData.reducedEdu,
                              ////////////////////////////////////
                              taxOffice: vehicleData.taxOffice,
                              totalTaxationKRW: vehicleData.totalTaxationKRW,
                              vehicleRegNo: vehicleData.vehicleRegNo,
                              modelYear: vehicleData.modelYear,
                              dpVolume: vehicleData.dpVolume,

                              //개소세 교육세 추가
                              indv: vehicleData.indv,
                              edu: vehicleData.edu,
                            })
                          : null
                      }
                      url="/pdfdfco"
                      inquiryHandler={inquiryHandler}
                      vehicleData={vehicleData}
                    />

                    <IndvFileUploader
                      title="BMW Korea 승용차 개별소비세 면세 반출 신고서"
                      type="acb"
                      isUploaded={isUploaded}
                      isConfirmed={isConfirmed}
                      deleteAuthority="AONEBMW"
                      vin={vehicleData ? vehicleData.vin : null}
                      inquiryAttachedFiles={inquiryAttachedFiles}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
            {authState.includes("AONEBMW") && (
              <Box
                sx={{
                  borderRadius: "50px",
                  background: "#ffffff",
                  boxShadow:
                    "20px 20px 60px #d9d9d9,             -20px -20px 60px #ffffff",
                }}
              >
                <RepBox
                  vehicleData={vehicleData}
                  inquiryHandler={inquiryHandler}
                />
              </Box>
            )}
            {authState.includes("AONEBMW") && (
              <Box
                sx={{
                  borderRadius: "50px",
                  background: "#ffffff",
                  boxShadow:
                    "20px 20px 60px #d9d9d9,             -20px -20px 60px #ffffff",
                }}
              >
                <BuyerBox
                  vehicleData={vehicleData}
                  inquiryHandler={inquiryHandler}
                />
              </Box>
            )}
            {authState.includes("AONEBMW") && (
              <Box
                sx={{
                  borderRadius: "50px",
                  background: "#ffffff",
                  boxShadow:
                    "20px 20px 60px #d9d9d9,             -20px -20px 60px #ffffff",
                }}
              >
                <DealerBox
                  vehicleData={vehicleData}
                  inquiryHandler={inquiryHandler}
                />
              </Box>
            )}
            <Box
              sx={{
                borderRadius: "50px",
                background: "#ffffff",
                boxShadow:
                  "20px 20px 60px #d9d9d9,             -20px -20px 60px #ffffff",
              }}
            >
              <Card variant="outlined" sx={{ borderRadius: 3 }}>
                <CardHeader
                  title="개별서류업로드"
                  sx={{ backgroundColor: "#eee" }}
                />

                <CardContent>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 3 }}
                  >
                    <IndvFileUploader
                      title="수입신고필증"
                      type={1}
                      isUploaded={isUploaded}
                      isConfirmed={isConfirmed}
                      vin={vehicleData ? vehicleData.vin : null}
                      inquiryAttachedFiles={inquiryAttachedFiles}
                    />

                    {/*판매일자를 딜러사가 입력 시 신청기한 확인할 수 있는 팝업생성 필요,
                     수식 : 판매일자로부터 6개월이 속하는 달의 마지막 날까지 신청기한 팝업 
                     (굳이 팝업방식이 아니여도 기한이 확인될수 있으면 됨)*/}
                    <IndvFileUploader
                      title="판매세금계산서"
                      type={2}
                      isUploaded={isUploaded}
                      isConfirmed={isConfirmed}
                      vin={vehicleData ? vehicleData.vin : null}
                      inquiryAttachedFiles={inquiryAttachedFiles}
                    />
                    <IndvFileUploader
                      title="자동차등록증"
                      type={3}
                      isUploaded={isUploaded}
                      isConfirmed={isConfirmed}
                      vin={vehicleData ? vehicleData.vin : null}
                      inquiryAttachedFiles={inquiryAttachedFiles}
                    />
                    {vehicleData && vehicleData.type === "장애인" ? (
                      <>
                        <Typography variant="h5">
                          ※해당되는 등록증을 첨부하세요
                        </Typography>
                        <IndvFileUploader
                          title="장애인 등록증 / 고엽제 등록증 / 국가유공자 등록증"
                          type={5}
                          isUploaded={isUploaded}
                          isConfirmed={isConfirmed}
                          vin={vehicleData ? vehicleData.vin : null}
                          inquiryAttachedFiles={inquiryAttachedFiles}
                        />
                        <IndvFileUploader
                          title="가족관계증명서(본인명의 아닐시)"
                          type={6}
                          isUploaded={isUploaded}
                          isConfirmed={isConfirmed}
                          vin={vehicleData ? vehicleData.vin : null}
                          inquiryAttachedFiles={inquiryAttachedFiles}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {vehicleData && vehicleData.type === "여객" ? (
                      <>
                        <IndvFileUploader
                          title="사업자등록증"
                          type={7}
                          isUploaded={isUploaded}
                          isConfirmed={isConfirmed}
                          vin={vehicleData ? vehicleData.vin : null}
                          inquiryAttachedFiles={inquiryAttachedFiles}
                        />
                        <IndvFileUploader
                          title="자동차 여객사업 등록증"
                          type={8}
                          isUploaded={isUploaded}
                          isConfirmed={isConfirmed}
                          vin={vehicleData ? vehicleData.vin : null}
                          inquiryAttachedFiles={inquiryAttachedFiles}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {vehicleData && vehicleData.type === "렌터카" ? (
                      <>
                        <IndvFileUploader
                          title="사업자등록증"
                          type={7}
                          isUploaded={isUploaded}
                          isConfirmed={isConfirmed}
                          vin={vehicleData ? vehicleData.vin : null}
                          inquiryAttachedFiles={inquiryAttachedFiles}
                        />
                        <IndvFileUploader
                          title="자동차 여객사업 등록증"
                          type={8}
                          isUploaded={isUploaded}
                          isConfirmed={isConfirmed}
                          vin={vehicleData ? vehicleData.vin : null}
                          inquiryAttachedFiles={inquiryAttachedFiles}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {vehicleData && vehicleData.type === "다자녀" ? (
                      <>
                        <IndvFileUploader
                          title="가족관계증명서"
                          type={9}
                          isUploaded={isUploaded}
                          isConfirmed={isConfirmed}
                          vin={vehicleData ? vehicleData.vin : null}
                          inquiryAttachedFiles={inquiryAttachedFiles}
                        />
                        <IndvFileUploader
                          title="주민등록등본"
                          type={10}
                          isUploaded={isUploaded}
                          isConfirmed={isConfirmed}
                          vin={vehicleData ? vehicleData.vin : null}
                          inquiryAttachedFiles={inquiryAttachedFiles}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            borderRadius: "50px",
            background: "#ffffff",
            boxShadow:
              "20px 20px 60px #d9d9d9,             -20px -20px 60px #ffffff",
          }}
        >
          <Card variant="outlined" sx={{ borderRadius: 3 }}>
            <CardHeader title="Comments" sx={{ backgroundColor: "#eee" }} />
            <CardContent>
              <ActivityCheck
                HistoryHandler={HistoryHandler}
                vehicleData={vehicleData}
              />
              <ActivityHistory
                active={active}
                HistoryHandler={HistoryHandler}
                setIsConfirmed={setIsConfirmed}
              />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default AppliedComponent;
