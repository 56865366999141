import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import { spinnerAtom } from "../../states/Atoms/StatusAtoms";
import {
  crnState,
  finishState,
  startState,
  typeState,
  vehiclesState,
} from "../../states/Atoms/vehiclesAtom";

const useGetIndvreduction = () => {
  const [start, setStart] = useRecoilState(startState);
  const [finish, setFinish] = useRecoilState(finishState);
  const [crn, setCrn] = useRecoilState(crnState);

  const setSpinner = useSetRecoilState(spinnerAtom);
  const setVehiclesData = useSetRecoilState(vehiclesState);
  const type = useRecoilValue(typeState);

  const InquiryApplicationHandler = async (e) => {
    if (start > finish) {
      alert("시작일을 종료일 이후로 설정할 수 없습니다");
      return;
    }

    try {
      setSpinner(true);

      const result = await IVISAxios.get(`/indvreduction`, {
        params: {
          start: start,
          end: finish,
          column: type,
        },
      });

      setVehiclesData(
        crn === "all"
          ? result.data
          : result.data.filter((item) => item.crn === crn)
      );
    } catch (e) {
      console.log(e);
    } finally {
      //스피너
      setSpinner(false);
    }
  };

  return {
    InquiryApplicationHandler,
    start,
    finish,
    crn,
    setStart,
    setFinish,
    setCrn,
  };
};

export default useGetIndvreduction;
