import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

const AppliedStatusTable = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow
            key="상태"
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              상태
            </TableCell>
            <TableCell component="th" scope="row">
              {data.status}
            </TableCell>
          </TableRow>
          <TableRow
            key="신청일"
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              신청일
            </TableCell>
            <TableCell component="th" scope="row">
              {data.createdAt
                ? moment(data.createdAt).format("YYYY/MM/DD")
                : "-"}
            </TableCell>
          </TableRow>
          <TableRow
            key="세관접수일"
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              세관접수일
            </TableCell>
            <TableCell component="th" scope="row">
              {data.appliedAt
                ? moment(data.appliedAt).format("YYYY/MM/DD")
                : "-"}
            </TableCell>
          </TableRow>
          <TableRow
            key="승인일"
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              승인일
            </TableCell>
            <TableCell component="th" scope="row">
              {data.approvedAt
                ? moment(data.approvedAt).format("YYYY/MM/DD")
                : "-"}
            </TableCell>
          </TableRow>
          <TableRow
            key="지급일"
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              지급일
            </TableCell>
            <TableCell component="th" scope="row">
              {data.paidAt ? moment(data.paidAt).format("YYYY/MM/DD") : "-"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default AppliedStatusTable;
