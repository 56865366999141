import { Link } from "react-router-dom";
import "./OverlayNav.css";
import { authoritiesAtom } from "../../states/Atoms/AuthAtoms";
import { useRecoilValue } from "recoil";

const OverlayNav = (props) => {
  const authState = useRecoilValue(authoritiesAtom);

  return (
    <div
      id="myNav"
      className={"overlay content" + (props.toggle ? "" : " d-none")}
    >
      <div className="overlay-content">
        <div className="flex-row-top">
          <div className="flex-column">
            <span className="overlayheader">TYPE1</span>
            <div className="flex-column">
              <Link
                to="/search"
                onClick={() => {
                  props.setMenuToggle(false);
                }}
              >
                <span>차량조회</span>
              </Link>
              <Link
                to="/chart"
                onClick={() => {
                  props.setMenuToggle(false);
                }}
              >
                <span>기간/일괄조회</span>
              </Link>
            </div>
          </div>

          {/* <div className="flex-column">
            <span className="overlayheader">TYPE2</span>
            <div className="flex-column">
              
              <Link to="BoardPage">
                <span>Q/A</span>
              </Link>
            </div>
          </div>
          <div className="flex-column">
            <span className="overlayheader">TYPE3</span>
            <div className="flex-column">
              <Link to="AdminPage">
                <span>Admin</span>
              </Link>
            </div>
          </div>
          <div className="flex-column">
            <span className="overlayheader">TYPE4</span>
            <div className="flex-column">
              <Link to="">
                <span>menu</span>
              </Link>
              <Link to="">
                <span>menu</span>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OverlayNav;
