import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import Budget from "../Component/DashBoard/Budget";
import TotalCustomers from "../Component/DashBoard/TotalCustomers";
import TasksProgress from "../Component/DashBoard/TasksProgress";
import TotalProfit from "../Component/DashBoard/TotalProfit";
import Sales from "../Component/DashBoard/Sales";
import TrafficByDevice from "../Component/DashBoard/TrafficByDevice";
import LatestProducts from "../Component/DashBoard/LatestProducts";
import LatestComments from "../Component/DashBoard/LatestComments";
import { AuthUserInfo } from "../states/Atoms/AuthRecoil";
import { spinnerAtom } from "../states/Atoms/StatusAtoms";
import { authoritiesAtom } from "../states/Atoms/AuthAtoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { IVISAxios } from "../Modules/Axios/IVISAxios";

const DashBoardPage = () => {
  const [spinner, SetSpinner] = useRecoilState(spinnerAtom);
  useEffect(() => {
    (async () => {
      try {
        SetSpinner(true);
        await unreadHandler();
      } catch (e) {
      } finally {
        SetSpinner(false);
      }
    })();
  }, []);
  const authState = useRecoilValue(authoritiesAtom);

  const [unreadData, setUnreadData] = useState([]);
  const unreadHandler = async () => {
    try {
      const result = await IVISAxios.get("/applhistory/unread", {
        params: {
          writtenBy: authState.includes("AONEBMW")
            ? "D"
            : authState.includes("BMWDEALER")
            ? "A"
            : null,
        },
      });
      if (result.data.success) {
        setUnreadData(result.data);
      } else {
        setUnreadData({});
      }
      // console.log(result);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      {authState.includes("BMWDEALERSALES") ? null : (
        <Box
          sx={{
            backgroundColor: "background.default",
            minHeight: "100%",
            py: 3,
          }}
        >
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Budget />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <TotalCustomers />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <TasksProgress />
              </Grid>
              <Grid item lg={3} sm={6} xl={3} xs={12}>
                <TotalProfit sx={{ height: "100%" }} />
              </Grid>
              <Grid item lg={8} md={12} xl={9} xs={12}>
                <Sales />
              </Grid>
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <TrafficByDevice sx={{ height: "100%" }} />
              </Grid>
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <LatestProducts sx={{ height: "100%" }} />
              </Grid>
              <Grid item lg={8} md={12} xl={9} xs={12}>
                <LatestComments unreadData={unreadData} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </div>
  );
};

export default DashBoardPage;
