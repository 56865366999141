import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function EcoFriendlyPopover({ text }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ display: "flex", alignItems: "center", gap: "5px" }}
      >
        <InfoOutlinedIcon />
        <span
          style={{
            background:
              "linear-gradient(180deg,rgba(255,255,255,0) 50%, #FFFF00 50%)",
          }}
        >
          {text}
        </span>
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ width: "300px", p: 1, fontSize: "12px" }}>
          이 차량은 조세특례제한법에 따라 수입신고 시 개별소비세 및 교육세의
          전부 또는 일부를 면제받은 차량입니다. 수입신고 시에 면제받은
          개별소비세 및 교육세의 전부 또는 일부를 제외한 세액으로 조회되는 점
          참고 부탁 드립니다.
        </Typography>
      </Popover>
    </div>
  );
}
