import { useState } from "react";
import { useSetRecoilState } from "recoil";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { makeStyles } from "@mui/styles";

import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import { exportExcel } from "../../utils/excel";
import ExcelIcon from "../../icons/ExcelIcon";
import { spinnerAtom } from "../../states/Atoms/StatusAtoms";

const useStyles = makeStyles({
  withDate: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "20px",
    width: "calc(100% - 40px)",
    maxWidth: "650px",
    border: "2px solid #efefef",
    borderRadius: "5px",
    boxShadow:
      "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  },
  noDate: (props) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    width: "calc(100% - 40px)",
    maxWidth: "650px",
    padding: "20px",
    border: "2px solid #efefef",
    borderRadius: "5px",
    boxShadow:
      "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
  }),
  title: {
    fontSize: "20px",
    margin: "0 0 10px 0",
  },
  tcmsNewTitle: {
    fontSize: "20px",
    margin: "0",
  },
});

const ExcelDownloadCard = ({ title, url, filename }) => {
  const [startDate, setStartDate] = useState(
    moment().subtract("1", "M").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const setIsLoading = useSetRecoilState(spinnerAtom);

  const classes = useStyles();

  const onChangeStartDate = (e) => {
    setStartDate(e.target.value);
  };
  const onChangeEndDate = (e) => {
    setEndDate(e.target.value);
  };
  const onClickDownloadButton = async () => {
    if (moment(endDate).isBefore(startDate)) {
      alert("올바른 기간을 선택해주세요.");
      return;
    }

    try {
      setIsLoading(true);
      const params = !title.includes("New")
        ? {
            startDate: startDate,
            endDate: endDate,
          }
        : null;

      const res = await IVISAxios.get(`${url}`, {
        params,
        headers: {
          "Content-type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8",
        },
        responseType: "arraybuffer",
      });

      console.log("res ", res.data);

      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      //   link.download = `${params.startDate}-${params.endDate}_${filename}.xlsx`;
      const downloadFilename = !title.includes("New")
        ? `${params.startDate}-${params.endDate}_${filename}.xlsx`
        : `${filename}.xlsx`;
      link.download = downloadFilename;
      link.click();
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      component="section"
      className={!title.includes("New") ? classes.withDate : classes.noDate}
    >
      <Typography
        variant="h2"
        sx={{ fontSize: "20px" }}
        className={!title.includes("New") ? classes.title : classes.tcmsNew}
      >
        {title}

        {!title.includes("New") ? (
          <Typography
            sx={{
              fontSize: "15px",
              margin: "10px 0 0 0",
              color: "red",
            }}
          >
            {" "}
            * 날짜 기준은 수리 일자 기준으로 계산 됩니다
          </Typography>
        ) : (
          <Typography
            sx={{
              fontSize: "15px",
              margin: "10px 0 0 0",
              color: "red",
            }}
          >
            * 날짜 기준은 마스터등록 일시로 계산 되며 한달 주기로 고정 되어
            있습니다.
          </Typography>
        )}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        {!title.includes("New") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "10px",
              width: "100%",
            }}
          >
            <TextField
              id="date"
              label="Start"
              type="date"
              value={startDate}
              sx={{ width: 220 }}
              onChange={onChangeStartDate}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <span>-</span>

            <TextField
              id="date"
              label="End"
              type="date"
              sx={{ width: 220 }}
              value={endDate}
              onChange={onChangeEndDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        )}

        <Button
          type="button"
          variant="outlined"
          onClick={onClickDownloadButton}
          aria-label="엑셀 다운로드 버튼"
          title="엑셀 다운로드 버튼"
          sx={{
            minWidth: "80px",
            minHeight: "56px",
            border: "1px solid rgb(22 163 74 / 1)",
            backgroundColor: "rgb(22 163 74 / 1)",

            "&:hover": {
              backgroundColor: "#00ae00",
              borderColor: "#00ae00",
            },
          }}
        >
          <ExcelIcon style={{ width: "24px", height: "24px" }} />
          <FileDownloadIcon
            sx={{ width: "24px", height: "24px", color: "#ffffff" }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default ExcelDownloadCard;
