import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
// import { configureStore } from "@reduxjs/toolkit";

// export default configureStore({
//   reducer: {},
// });

let initialState = {
  accessToken: "",
};
const persistConfig = {
  key: "root",
  storage,
};
const accessTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case "accessToken/set":
      return { ...state, accessToken: action.accessToken };
    default:
      return state;
  }
};
const persistedReducer = persistReducer(persistConfig, accessTokenReducer);
let combineReducer = combineReducers({
  // listing: ListingReducer, //function
  accessToken: persistedReducer, //function
});

const store = createStore(persistedReducer, initialState);
export const persistor = persistStore(store);
export default store;
