import React, { useState } from "react";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import styled from "styled-components";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";

const Register = () => {
  const history = useHistory();

  //회원가입 정보 담기
  const [userID, setUserID] = useState("");
  const [userPW, setUserPW] = useState("");
  const [userPWCheck, setUserPWCheck] = useState("");
  const [userPWError, setUserPWError] = useState(false);
  const [userName, setUserName] = useState("");
  const [userTel, setUserTel] = useState("");
  const [useCrn, setUserCrn] = useState("");

  const onChangeid = (e) => {
    setUserID(e.target.value);
  };
  const onChangepw = (e) => {
    setUserPWError(e.target.value !== userPWCheck);
    setUserPW(e.target.value);
  };

  const onChangePasswordChk = (e) => {
    //비밀번호를 입력할때마다 password 를 검증하는 함수
    setUserPWError(e.target.value !== userPW);
    setUserPWCheck(e.target.value);
  };
  const onChangeName = (e) => {
    setUserName(e.target.value);
  };
  const onChangeTel = (e) => {
    setUserTel(e.target.value);
  };

  const onChangecrn = (e) => {
    setUserCrn(e.target.value);
  };

  //라디오박스
  const [authorities, setAuthorities] = useState("");
  const handleChangeRadio = (event) => {
    setAuthorities(event.target.value);
  };
  console.log("권한 :", authorities);

  //브랜드 체크

  const [brandBMW, setBrandBMW] = useState(false);
  const [brandMINI, setBrandMINI] = useState(false);

  const BrandChange = (event) => {
    setBrandBMW(event.target.checked);
  };
  const BrandChange2 = (event) => {
    setBrandMINI(event.target.checked);
  };

  console.log("BMW체크 : ", brandBMW);
  console.log("MINI체크 :", brandMINI);

  const registerHandler = async (e) => {
    const registerInfo = new URLSearchParams({
      id: userID,
      password: userPW,
      name: userName,
      crn: useCrn,
      tel: userTel,
      brand:
        brandBMW && brandMINI ? "BM" : brandBMW ? "B" : brandMINI ? "M" : null,
    });
    registerInfo.append("authorities", authorities);
    registerInfo.append("authorities", "USER");
    try {
      const result = await IVISAxios.post("/register", registerInfo);
      alert("회원가입 완료");
      console.log("회원가입 완료");
      window.location.replace("/login");
      console.log(result);
    } catch (e) {
      console.log("회원가입 실패");
      console.log(e);
    }
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <form>
            <Formbox>
              <Box sx={{ mb: 3 }}>
                <Typography color="textPrimary" variant="h2">
                  IVIS Register Page
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Aone-Customs
                </Typography>
              </Box>
            </Formbox>
            <TextField
              fullWidth
              label="ID"
              margin="normal"
              variant="outlined"
              onChange={onChangeid}
            />

            <TextField
              fullWidth
              label="Password"
              type="Password"
              margin="normal"
              variant="outlined"
              onChange={onChangepw}
            />

            <TextField
              fullWidth
              value={userPWCheck}
              label="Password Check"
              type="Password"
              margin="normal"
              variant="outlined"
              onChange={onChangePasswordChk}
            />
            {userPWError ? (
              <div style={{ color: "red" }}>비밀번호가 일치하지 않습니다.</div>
            ) : (
              <></>
            )}

            <TextField
              fullWidth
              label="사용자명"
              margin="normal"
              variant="outlined"
              onChange={onChangeName}
            />

            <TextField
              fullWidth
              label="연락처"
              margin="normal"
              variant="outlined"
              onChange={onChangeTel}
            />

            <TextField
              fullWidth
              label="사업자 번호"
              margin="normal"
              variant="outlined"
              onChange={onChangecrn}
            />

            <h3>권한설정</h3>
            <div>
              <Radio
                checked={authorities === "AONEBMW"}
                onChange={handleChangeRadio}
                value="AONEBMW"
              />
              에이원
              <Radio
                checked={authorities === "BMWKOREA"}
                onChange={handleChangeRadio}
                value="BMWKOREA"
              />
              BMW코리아
              <Radio
                checked={authorities === "BMWDEALER"}
                onChange={handleChangeRadio}
                value="BMWDEALER"
              />
              딜러
              <Radio
                checked={authorities === "BMWDEALERSALES"}
                onChange={handleChangeRadio}
                value="BMWDEALERSALES"
              />
              딜러영업사원
              <Radio
                checked={authorities === "BOSU"}
                onChange={handleChangeRadio}
                value="BOSU"
              />
              보수전용
            </div>

            <h3>브랜드</h3>
            <div>
              <Checkbox
                checked={brandBMW}
                onChange={BrandChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              BMW
              <Checkbox
                checked={brandMINI}
                onChange={BrandChange2}
                inputProps={{ "aria-label": "controlled" }}
              />
              MINI
            </div>

            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={registerHandler}
                disabled={
                  !userID || userPWError || !authorities || !useCrn || !userName
                }
              >
                Sign up now
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Register;

const Formbox = styled.div`
  margin-top: 30px;
`;
