// https://www.npmjs.com/package/json-as-xlsx

import React from "react";
import xlsx from "json-as-xlsx";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import moment from "moment";
import Typography from "@mui/material/Typography";
import { CRN_MAP } from "../../utils/crn-mapper";

const JSONasXlsx = ({ vehicleData }) => {
  //해당 fields 명을 입력
  const ExportToExcel = () => {
    xlsx(data, settings);
  };
  let data = [
    {
      sheet: "sheet1",
      columns: [
        { label: "Brand", value: "brand" },
        { label: "차대번호", value: "vin" },
        { label: "타입", value: "type" },

        //2022-02-04 수입신고번호 환급신청번호 추가요청- 양민주팀장님
        { label: "수입신고번호", value: "importNumber" },
        { label: "환급신청번호", value: "appNumber" },
        {
          label: "신청일자",
          value: (row) =>
            row.createdAt ? moment(row.createdAt).format("YYYY/MM/DD") : "",
        },
        {
          label: "접수일자",
          value: (row) =>
            row.appliedAt ? moment(row.appliedAt).format("YYYY/MM/DD") : "",
        },
        {
          label: "승인일자",
          value: (row) =>
            row.approvedAt ? moment(row.approvedAt).format("YYYY/MM/DD") : "",
        },
        {
          label: "지급일자",
          value: (row) =>
            row.paidAt ? moment(row.paidAt).format("YYYY/MM/DD") : "",
        },
        {
          label: "딜러사",
          value: (row) => CRN_MAP.get(row.crn),
        },
        { label: "마감기한", value: "deadLine" },
        { label: "총 환급금", value: "totalRefund" },
        { label: "환급예상 개소세", value: "reducedIndv" },
        { label: "환급예상 교육세", value: "reducedEdu" },
        { label: "개소세", value: "indv" },
        { label: "교육세", value: "edu" },
        { label: "장애등급", value: "dg" },
        { label: "구매자명", value: "buyerName" },
        { label: "회사명", value: "buyerCompanyName" },
        { label: "주소", value: "buyerAddress" },
        { label: "관할세무서", value: "taxOffice" },
        { label: "판매．반출장소", value: "buyerPlace" },
        { label: "사업자등록번호", value: "buyerCRN" },
        { label: "주민등록번호", value: "buyerSSNumber" },
        { label: "전화번호", value: "buyerTel" },
        { label: "자동차등록번호", value: "vehicleRegNo" },
      ],
      content: vehicleData,
    },
  ];

  let settings = {
    fileName: `IVIS_EXPORT_${moment().format("YYYYMMDDhhmmss")}`,
    writeOptions: {},
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        border: "1px solid #1976de",
        borderRadius: "4px",
        height: "38px",
      }}
    >
      <Button type="button" onClick={ExportToExcel}>
        <Typography variant="caption">EXCEL</Typography>
        <SystemUpdateAltIcon />
      </Button>
    </Box>
  );
};

export default JSONasXlsx;
