import "./Header.css";
import Nav from "../Nav/Nav";
import aone_logo from "../../img/logo/aone.png";
import Styled from "styled-components";
import { authoritiesAtom } from "../../states/Atoms/AuthAtoms";
import { useRecoilValue } from "recoil";
import Cookies from "universal-cookie";
import { useRecoilState } from "recoil";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import { loginStatusAtom } from "../../states/Atoms/AuthAtoms";

import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";

const cookies = new Cookies();
const Header = (props) => {
  //중복방지 떄문에 authStated 로 변경
  const [authStated, setAuthState] = useRecoilState(authoritiesAtom);
  const [loginStatus, setLoginStatus] = useRecoilState(loginStatusAtom);

  const LogoutHandler = () => {
    async function Logout() {
      await IVISAxios.get("/logout");
    }
    Logout();
    localStorage.setItem("access_Token", null);
    setLoginStatus(null);
    setAuthState([]);
    //없어지고 난 이후 Login이 안됨

    window.location.replace("/login");
  };

  const authState = useRecoilValue(authoritiesAtom);
  // console.log(authState);

  return (
    <>
      <header>
        <div className="container flex-row-nowrap">
          <a href="/">
            <img
              className="item"
              id="aone_logo"
              src={aone_logo}
              alt="logo"
            ></img>
          </a>
          <Nav
            className="item"
            toggle={props.toggle}
            toggleFunction={(val) => props.toggleFunction(val)}
          />
          {/* {isLoggedIn === "true" && authState[1] === "AONEBMW" ? (
          <DealerLogo>ADMIN 님이 로그인</DealerLogo>
          ) : null}

          {isLoggedIn === "true" && authState[0] === "BMWDEALER" ? (
            <DealerLogo>DEALER 님이 로그인</DealerLogo>
          ) : null} */}
          {/*로고이미지 추가시 적용 예정*/}
          {authState && authState.includes("AONEBMW") ? (
            <DealerLogo>ADMIN 님이 로그인</DealerLogo>
          ) : null}
          {authState && authState.includes("BMWDEALER") ? (
            <DealerLogo>DEALER 님이 로그인</DealerLogo>
          ) : null}
          {authState && authState.includes("BMWDEALERSALES") ? (
            <DealerLogo>영업사원 님이 로그인</DealerLogo>
          ) : null}
          {authState && authState.includes("BOSU") ? (
            <DealerLogo>보수작업 님이 로그인</DealerLogo>
          ) : null}
          {localStorage.getItem("access_Token") === "null" ? null : (
            <IconButton
              variant="contained"
              onClick={LogoutHandler}
              color="error"
            >
              <LogoutIcon />
            </IconButton>
          )}

          {/* <button onClick={LogoutHandler}>로그아웃</button> */}
        </div>
      </header>
    </>
  );
};
export default Header;

const DealerLogo = Styled.div`
font-size : 10px;
margin-left:20px
`;
