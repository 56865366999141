// https://www.npmjs.com/package/json-as-xlsx

import React from "react";
import xlsx from "json-as-xlsx";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import Typography from "@mui/material/Typography";
const JSONasXlsx = ({ vehicleData, radioType }) => {
  console.log(vehicleData);
  //해당 fields 명을 입력
  const ExportToExcel = () => {
    xlsx(data, settings);
  };
  let data = [
    {
      sheet: "샘플",

      columns: [
        { label: "인보이스번호", value: "invNo" },
        { label: "제품코드", value: "itemCode" },
        { label: "제품명", value: "desc" },
        { label: "수량", value: "qty" },
        { label: "인증여부", value: "result" },
        { label: "성분", value: "components" },
        { label: "제조연월", value: "producedDateInfo" },
        { label: "마스터비고", value: "remark" },
      ],

      content:
        radioType === "인증"
          ? vehicleData.filter((x) => x.result)
          : vehicleData,
    },
  ];

  let settings = {
    fileName: `Preinspection_${moment().format("YYYYMMDDhhmmss")}`,
    writeOptions: {},
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <IconButton type="button" onClick={ExportToExcel}>
        <Typography variant="caption">EXCEL</Typography>
        <SystemUpdateAltIcon />
      </IconButton>
    </Box>
  );
};

export default JSONasXlsx;
