const GetBase64String = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const previewURL = reader.result.split(",")[1];
      // 2021/07/23 FormData를 이용한 이미지 업로드? 는 일단 보류
      // https://stackoverflow.com/questions/39663961/how-do-you-send-images-to-node-js-with-axios
      // return previewURL;
      resolve(previewURL);
    };
    reader.readAsDataURL(file);
  });

export default GetBase64String;
