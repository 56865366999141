import { useState } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import useGetIndvreduction from "../../hooks/api/useGetIndvreduction";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import { modalAtom } from "../../states/Atoms/StatusAtoms";

const TypeSelectForm = ({ vehicleData, inquiryHandler, buttonname }) => {
  //최종 업로드 Axios API

  //Type변경 예정
  const [type, setType] = useState(null);

  const setModalOpen = useSetRecoilState(modalAtom);

  const { InquiryApplicationHandler } = useGetIndvreduction();

  const applicationHandler = async (e) => {
    try {
      const result = await IVISAxios.patch(
        "/indvreduction",
        new URLSearchParams({
          vin: vehicleData.vin,
          type: type,
        })
      );
      alert("신청이 완료되었습니다.");
      inquiryHandler();
      InquiryApplicationHandler();
    } catch (error) {
      console.error(error);
    }
  };

  const handleApplyCancel = async (vin) => {
    try {
      if (window.confirm("신청취소를 진행하시겠습니까?")) {
        const res = await IVISAxios.delete(`/indvreduction/${vin}`);

        if (res.data.success) {
          alert("신청취소 성공");
          InquiryApplicationHandler();
          setModalOpen(false);
        } else {
          alert("신청취소 실패");
        }
        console.log("cancel res data : ", res.data);
      } else {
        alert("신청취소를 취소하셨습니다.");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const typelist = ["장애인", "다자녀", "렌터카"];

  return (
    <>
      <Form>
        <Autocomplete
          value={type}
          onChange={(event, newValue) => {
            setType(newValue);
          }}
          disablePortal
          id="combo-box-demo"
          options={typelist}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="신청타입변경" />
          )}
        />

        <Button
          variant="contained"
          onClick={applicationHandler}
          disabled={!type}
        >
          {buttonname}
        </Button>

        <Button
          variant="contained"
          sx={{
            width: "100px",
            background: "#D32F2F",
            "&:hover": { background: "#ff0000" },
          }}
          onClick={() => handleApplyCancel(vehicleData.vin)}
        >
          취소
        </Button>
      </Form>
    </>
  );
};

export default TypeSelectForm;

const Form = styled.form`
  display: flex;
  gap: 10px;
`;
