import React, { useEffect, useState } from "react";
import { IVISAxios } from "../Modules/Axios/IVISAxios";
import Button from "@mui/material/Button";
import styled from "styled-components";

const BoardForm = () => {
  //데이터 가져오기 실행

  // useEffect(() => {
  //   getdataHandler();
  // },[]);

  //게시판 데이터 담기
  const [boardData, setBoardData] = useState({});

  //데이터 가져오는 핸들러
  const getdataHandler = async () => {
    try {
      const result = await IVISAxios.get("/Board");
      setBoardData(result);
    } catch {}
  };
  console.log(boardData);

  return (
    <LayoutBox>
      <h3>Q/A 게시판</h3>
      <a href="/CreatePage">
        <Button>글쓰기</Button>
      </a>
      <br /> <br /> <br /> <br /> <br />
      list화 해서 게시글 읽기
      <ul>
        <li>
          <a href="/ReadPage">
            <Button>읽기1</Button>
          </a>
        </li>
        <li>
          <a href="/ReadPage">
            <Button>읽기2</Button>
          </a>
        </li>
        <li>
          <a href="/ReadPage">
            <Button>읽기3</Button>
          </a>
        </li>
      </ul>
      <br />
    </LayoutBox>
  );
};

export default BoardForm;

const LayoutBox = styled.div`
  margin-top: 50px;
  margin-left: 200px;
  margin-right: 200px;
`;
