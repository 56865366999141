import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import MoonLoader from "react-spinners/MoonLoader";
import styled from "styled-components";
import { css } from "@emotion/react";

import SearchPage from "./Pages/SearchPage";
import ChartPage from "./Pages/ChartPage";
import DashBoardPage from "./Pages/DashBoardPage";
import LoginPage from "./Pages/LoginPage";
import MuiLogin from "./Pages/MuiLoign,";
import RegisterPage from "./Pages/RegisterPage";
import BoardPage from "./Pages/BoardPage";
import Loading from "./Pages/Loading";
import CreatePage from "./Component/Board/CreatePage";
import ReadPage from "./Component/Board/ReadPage";
import UpdatePage from "./Component/Board/UpdatePage";
import AdminPage from "./Pages/AdminPage";
import PreinspectionPage from "./Pages/PreinspectionPage";
import PasswordPage from "./Pages/PasswordPage";
import Logout from "./Pages/Logout";
import WorksPage from "./Pages/WorksPage";

import SnackBar from "./Component/Alert/SnackBar";
import Footer from "./Component/Footer/Footer";
import Header from "./Component/Header/Header";
import OverlayNav from "./Component/Nav/OverlayNav";
import "./App.css";
import "./FlexBox.css";
// import Cookies from "universal-cookie";
import { IVISAxios } from "./Modules/Axios/IVISAxios";
import { authoritiesAtom } from "./states/Atoms/AuthAtoms";
import { loginStatusAtom } from "./states/Atoms/AuthAtoms";
import {
  snackbarAtom,
  spinnerAtom,
  toastAtom,
} from "./states/Atoms/StatusAtoms";

// const cookies = new Cookies();
const DURATION = 3000;

const App = () => {
  // 관리자 권한별 라우팅
  // const [isAdmin, setIsAdmin] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [snackState, setSnackState] = useRecoilState(toastAtom);
  const [authState, setAuthState] = useRecoilState(authoritiesAtom);
  const snackbarState = useRecoilValue(snackbarAtom);
  const spinner = useRecoilValue(spinnerAtom);
  const [isLoaded, setIsLoaded] = useState(false);
  const loginStatus = useRecoilValue(loginStatusAtom);
  // spinner https://www.davidhu.io/react-spinners/
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const [color, _] = useState("#ffffff");

  useEffect(() => {
    (async () => {
      // console.log(loginStatus);
      try {
        await IVISAxios.get("/authorities").then((result) => {
          if (result && result.data.success) {
            // setAuthState(result);
            setIsLoggedIn(true);
            setAuthState(result.data.authorities);
          } else {
            setAuthState([]);
          }
        });

        // 가져온 권한 리스트를 recoil에 삽입
      } catch (e) {
        console.log("err", e);
      } finally {
        setIsLoaded(true);
      }
    })();
  }, [loginStatus, isLoaded]);

  // console.log(isLoggedIn, authState);
  // console.log(isLoggedIn || authState.length > 0);
  // console.log(isLoaded);

  //스낵바 상탯값
  // const [snackOpen, setSnackOpen] = useState(false);
  return (
    <>
      {spinner ? (
        <LoaderDiv>
          <MoonLoader
            color={color}
            loading={spinner}
            css={override}
            size={150}
          />
        </LoaderDiv>
      ) : null}
      <SnackBar
        snackOpen={snackbarState.isOpen}
        duration={DURATION}
        text={snackbarState.text}
        severity={snackbarState.severity}
      />
      {/* <SnackBar
        snackOpen={snackState}
        setSnackOpen={setSnackState}
        duration={2000}
        text={snackbarText}
        severity={snackbarSeverity}
      /> */}

      <Router>
        <Header
          toggle={menuToggle}
          toggleFunction={(val) => setMenuToggle(val)}
        />
        <OverlayNav toggle={menuToggle} setMenuToggle={setMenuToggle} />

        <div className="content flex-column">
          <Switch>
            {/* 로그인 되어 있을 때는 대쉬보드로 리디렉션 필요 */}
            {/* <Route exact path="/login" component={LoginPage}/> */}
            {isLoaded ? (
              isLoggedIn || authState.length > 0 ? (
                <>
                  <Route exact path="/RegisterPage" component={RegisterPage} />
                  <Route exact path="/search/:vin" component={SearchPage} />
                  <Route exact path="/search" component={SearchPage} />
                  <Route
                    exact
                    path="/preinspection"
                    component={PreinspectionPage}
                  />
                  <Route exact path="/chart" component={ChartPage} />
                  <Route exact path="/" component={DashBoardPage} />
                  <Route exact path="/muilogin" component={MuiLogin} />
                  <Route exact path="/BoardPage" component={BoardPage} />
                  <Route exact path="/CreatePage" component={CreatePage} />
                  <Route exact path="/ReadPage" component={ReadPage} />
                  <Route exact path="/UpdatePage" component={UpdatePage} />
                  <Route exact path="/Admin" component={AdminPage} />
                  <Route exact path="/logout" component={Logout} />
                  <Route exact path="/Password" component={PasswordPage} />
                  <Route exact path="/works" component={WorksPage} />
                </>
              ) : (
                <Route component={LoginPage} />
              )
            ) : (
              <Route component={Loading} />
            )}
          </Switch>
        </div>
        <Footer />
      </Router>
    </>
  );
};

const LoaderDiv = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #ddda;
  z-index: 10000;
`;

export default App;
