import Hamburger from "../Icons/Hamburger";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import jwt_decode from "jwt-decode";

import "./Nav.css";
import "./mNav.css";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import { authoritiesAtom, tokenAtom } from "../../states/Atoms/AuthAtoms";

const Nav = (props) => {
  const authState = useRecoilValue(authoritiesAtom);
  const [token, setToken] = useRecoilState(tokenAtom);
  const history = useHistory();
  const [active, setActive] = useState("");
  useEffect(() => {
    setActive(history.location.pathname.replace(/\//g, ""));
  }, [history.location.pathname]);

  useEffect(() => {
    if (authState?.length > 0) {
      const decodedToken = jwt_decode(localStorage.getItem("access_Token"));
      setToken(decodedToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return (
    <nav>
      <div className="flex-row">
        <div className="topnav flex-row">
          {authState.includes("BOSU") ? (
            <a
              className={active === "preinspection" ? "active" : null}
              href="/preinspection"
            >
              보수작업
            </a>
          ) : authState.includes("BMWDEALERSALES") ? (
            <>
              <a
                className={active === "search" ? "active" : null}
                href="/search"
              >
                차량조회
              </a>

              <a
                className={active === "Password" ? "active" : null}
                href="/Password"
              >
                Password
              </a>
            </>
          ) : (
            <>
              <a
                className={active === "search" ? "active" : null}
                href="/search"
              >
                차량조회
              </a>
              <a className={active === "chart" ? "active" : null} href="/chart">
                기간/일괄조회
              </a>
              {/* <a href="/muilogin">MUI LOGIN</a> */}
              {/* <a href="/BoardPage">Q/A</a> */}
              {authState.includes("AONEBMW") && (
                <a
                  className={active === "Admin" ? "active" : null}
                  href="/Admin"
                >
                  Admin
                </a>
              )}
              {authState.includes("USER") && (
                <a
                  className={active === "Password" ? "active" : null}
                  href="/Password"
                >
                  Password
                </a>
              )}

              {token?.id === "master" && (
                <a
                  className={active === "works" ? "active" : null}
                  href="/works"
                >
                  Works
                </a>
              )}

              {authState.includes("USER") ? null : (
                <a
                  className={active === "login" ? "active" : null}
                  href="/login"
                >
                  LOGIN
                </a>
              )}
            </>
          )}
        </div>

        <Hamburger
          toggle={props.toggle}
          toggleFunction={(val) => props.toggleFunction(val)}
        />
      </div>
    </nav>
  );
};

export default Nav;
