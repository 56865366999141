import React from "react";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";

import Button from "@mui/material/Button";

import TextFiled from "@mui/material/TextField";
import CommentForm from "./CommentForm";
import styled from "styled-components";

const ReadPage = () => {
  return (
    <LayoutBox>
      <h3>읽기 페이지</h3>
      제목 : ~~~
      <br />
      작성자 : ~~~ <br />
      내용 : ~~~
      <br />
      <a href="/UpdatePage">
        <Button>수정</Button>
      </a>
      <CommentForm />
    </LayoutBox>
  );
};

export default ReadPage;

const LayoutBox = styled.div`
  margin-top: 50px;
  margin-left: 200px;
  margin-right: 200px;
`;
