import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// 2021/10/29 헤더 없는 테이블
const MUITableNoHeader = ({ source, match, onlyPrice }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(
      Object.entries(match).map((entry, index) => {
        return {
          columnName: entry[0],
          desc: entry[1],
          // value: !onlyPrice ? source[entry[0]] : source[entry[0]],
          value: source[entry[0]],
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, match]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {data.map(({ columnName, desc, value }) => {
            return (
              <TableRow
                key={columnName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {desc}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align={!onlyPrice ? "left" : "right"}
                >
                  {value}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default MUITableNoHeader;
