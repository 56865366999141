import TextField from "@mui/material/TextField";
import { useController } from "react-hook-form";

function TextInput({ name, rules, control, textFieldProps, ...props }) {
  const {
    field: { value, onChange },
    fieldState: { isDirty, isTouched, error },
  } = useController({
    name,
    rules,
    control,
  });

  return (
    <TextField
      value={value}
      onChange={onChange}
      {...textFieldProps}
      InputProps={{
        sx: {
          borderColor: `${error ? "red" : "green"}`,
        },
      }}
      {...props}
    />
  );
}

export default TextInput;
