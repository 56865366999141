import React, { useState, useEffect } from "react";
import TitleComponent from "../Component/Title/TitleComponent";
import IndvRefund from "../Component/business/refund/IndvRefund";
const SearchPage = (props) => {
  //예상환급액 조회

  const [inputtedVin, setInputtedVin] = useState("");
  useEffect(() => {
    if (props.match.params.vin) {
      setInputtedVin(props.match.params.vin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitleComponent Title="차량조회" type="2" />
      {/* <SnackBar
        message="차대번호 신청 완료"
        snackSetState={snackSetState}
      snackstate={snackstate}
      /> */}
      <IndvRefund vin={inputtedVin} />
    </>
  );
};

export default SearchPage;
