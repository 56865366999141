import React from "react";

import RegisterForm from "../Component/Register/RegisterForm";

const RegisterPage = () => {
  return (
    <div>
      <RegisterForm />
    </div>
  );
};

export default RegisterPage;
