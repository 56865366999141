import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { snackbarAtom } from "../../states/Atoms/StatusAtoms";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import TextInput from "../Common/TextInput";

const WorksComponent = () => {
  const setSnackbarState = useSetRecoilState(snackbarAtom);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      inv1: "",
      inv2: "",
      rptNo: "",
    },
  });

  const handleFormSubmit = async (data) => {
    try {
      const params = {
        inv1: data.inv1,
        inv2: data.inv2,
        rptNo: data.rptNo,
      };
      const res = await IVISAxios.get("/excel/rois/merge", { params });
      if (res.data.success) {
        const invoiceNumberArray = res.data?.data?.[0]?.map(
          (item) => item.invNo
        );
        setSnackbarState({
          isOpen: true,
          text: `인보이스 번호1: ${invoiceNumberArray[0]}
          인보이스 번호2: ${invoiceNumberArray[1]}
          신고서 번호: ${res.data?.data?.[0][0].rptNo} 병합 완료`,
          status: "success",
        });

        // alert(
        //   // `인보이스 번호1: ${invoiceNumberArray[0]}\n인보이스 번호2: ${invoiceNumberArray[1]}\n신고서 번호: ${res.data?.data?.[0][0].rptNo} ${res.data?.data?.[0][0].rptStatus} 완료`
        //   `인보이스 번호1: ${invoiceNumberArray[0]}\n인보이스 번호2: ${invoiceNumberArray[1]}\n신고서 번호: ${res.data?.data?.[0][0].rptNo} 병합 완료`
        // );
      } else {
        setSnackbarState({
          isOpen: true,
          text: "병합 실패",
          status: "warning",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        padding: "20px",
        width: "calc(100% - 40px)",
        maxWidth: "650px",
        border: "2px solid #efefef",
        borderRadius: "5px",
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",

        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center",
        // gap: "20px",
        // maxWidth: "650px",
        // height: "100%",
        // padding: "0 20px 0",
      }}
    >
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography component="h2" sx={{ fontSize: "20px" }}>
          BMW 롤스로이스 인보이스 신고 병합
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <TextInput
            name="inv1"
            control={control}
            rules={{ require: true }}
            textFieldProps={{ label: "인보이스 번호 1" }}
          />
          <TextInput
            name="inv2"
            control={control}
            rules={{ require: true }}
            textFieldProps={{ label: "인보이스 번호 2" }}
          />
          <TextInput
            name="rptNo"
            control={control}
            rules={{ require: true }}
            textFieldProps={{ label: "신고서 번호" }}
          />

          <Button
            type="submit"
            variant="contained"
            sx={{
              width: "80px",
              height: "100%",
            }}
            value="TCMS 병합"
            onClick={() => {}}
          >
            병합
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default WorksComponent;
