import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import GetBase64String from "../../Modules/helpers/base64";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { useRecoilValue, useRecoilState } from "recoil";
import { authoritiesAtom } from "../../states/Atoms/AuthAtoms";
import { spinnerAtom } from "../../states/Atoms/StatusAtoms";
import { Typography } from "@mui/material";

const FileUploader = ({
  vin,
  type,
  isUploaded,
  inquiryAttachedFiles,
  isConfirmed,
  deleteAuthority,
}) => {
  const authState = useRecoilValue(authoritiesAtom);
  const [fileName, setfileName] = useState();
  const [spinner, setSpinner] = useRecoilState(spinnerAtom);
  const uploader = useRef(null);

  const FileEventHandler = async (e) => {
    setfileName(e.target.files[0].name);
    setSpinner(true);
    try {
      const uploadData = await GetBase64String(e.target.files[0]);
      const result = await IVISAxios.post(
        "/attached",
        new URLSearchParams({
          vin: vin,
          type: type,
          data: uploadData,
          fileName: e.target.files[0].name,
        })
      );

      // setIsUploaded((prevState) => {
      //   console.log(prevState);
      //   return { ...prevState, [type]: new Date().toUTCString() };
      // });

      inquiryAttachedFiles(vin);
      uploader.current.value = "";
    } catch (e) {
      console.log(e);
    } finally {
      setSpinner(false);
    }
  };

  // 업로드된 파일 삭제하는 버튼 핸들러
  const handleFileDelete = async (e) => {
    setSpinner(true);
    try {
      const result = await IVISAxios.delete("/attached", {
        data: new URLSearchParams({
          vin: vin,
          type: type,
        }),
      });
      // setIsUploaded((prevState) => {
      //   return { ...prevState, [type]: null };
      // });
      inquiryAttachedFiles(vin);
    } catch (e) {
      console.log(e);
      alert("삭제실패");
    } finally {
      setSpinner(false);
    }
  };

  const FileDownloadHandler = async (e) => {
    setSpinner(true);
    try {
      const result = await IVISAxios.get(`/attached`, {
        params: {
          vin: vin,
          type: type,
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", isUploaded[type].fileName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      {isConfirmed ? (
        //Confirm 된 상태
        <>
          {isUploaded && isUploaded[type] ? (
            <div>
              등록일자 : {new Date(isUploaded[type].createdAt).toLocaleString()}
            </div>
          ) : null}

          {isUploaded && isUploaded[type] ? (
            <div>파일명 : {isUploaded[type].fileName}</div>
          ) : null}
        </>
      ) : (
        //Confirm 되지 않은상태
        <Box sx={{ display: "flex", flex: 1 }}>
          <Box sx={{ display: "flex", flex: 1 }}>
            {(!isUploaded || !isUploaded[type]) && (
              <TextField
                type="file"
                ref={uploader}
                onChange={FileEventHandler}
                disabled={
                  (isUploaded && isUploaded[type]) ||
                  (deleteAuthority && !authState.includes("AONEBMW"))
                }
                sx={{ flex: 1 }}
              />
            )}
            {isUploaded && isUploaded[type] && (
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Box>
                  {isUploaded && isUploaded[type] ? (
                    <div>{isUploaded[type].fileName}</div>
                  ) : null}
                </Box>
                <Box>
                  {isUploaded && isUploaded[type] ? (
                    <Typography variant="caption">
                      {moment(isUploaded[type].createdAt).format(
                        "YYYY/MM/DD HH:mm"
                      )}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
            )}
            {!deleteAuthority || authState.includes(deleteAuthority) ? (
              <IconButton
                variant="contained"
                color="error"
                onClick={handleFileDelete}
                disabled={!isUploaded || !isUploaded[type]}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            ) : null}

            <IconButton
              onClick={FileDownloadHandler}
              disabled={!isUploaded || !isUploaded[type]}
            >
              <DownloadIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FileUploader;
