import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const SearchCalender = ({ date, setDate, InquiryApplicationHandler }) => {
  const onChangeDate = (e) => {
    setDate(e.target.value);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          flex: 1,
        }}
      >
        <TextField
          id="date"
          label="날짜선택"
          type="date"
          value={date}
          sx={{ width: 220 }}
          onChange={onChangeDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button variant="outlined" onClick={InquiryApplicationHandler}>
          <SearchIcon />
        </Button>
      </Box>
    </>
  );
};

export default SearchCalender;
