//https://shyunju7.tistory.com/37

//https://blog.javabom.com/minhee/session/storage/localstorage-sessionstorage/react

import axios from "axios";
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useHistory } from "react-router-dom";
import LoginImage from "../../img/backgrounds/login-bmw.jpg";
import { FadeInDown } from "../../keyframes/TextAnimation";
import Cookies from "universal-cookie";
import { loginURL } from "../../Config/Serverinfo";
import { useDispatch } from "react-redux";
import tokenStore from "../../states/Stores/store";
import {
  isLoginAtom,
  loginStatusAtom,
  tokenAtom,
  userAtom,
} from "../../states/Atoms/AuthAtoms";
import { authoritiesAtom } from "../../states/Atoms/AuthAtoms";
import { useRecoilState, useRecoilValue } from "recoil";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();
const LoginForm = () => {
  //권한별 렌더링

  const authState = useRecoilValue(authoritiesAtom);
  const [loginStatus, setLoginStatus] = useRecoilState(loginStatusAtom);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [token, setToken] = useRecoilState(tokenAtom);
  const history = useHistory();
  const [userID, setUserID] = useState("");
  const [userPW, setUserPW] = useState("");
  const [accessToken, setAccessToken] = useState();
  const dispatch = useDispatch();

  const loginHandler = async () => {
    //로그인 API
    // const loginInfo = new URLSearchParams({
    //   id: userID,
    //   password: userPW,
    // });
    if (userPW && userID) {
      try {
        const result = await axios.post(
          loginURL,
          new URLSearchParams({
            id: userID,
            password: userPW,
          }),
          {
            withCredentials: true,
            origin: [
              "http://localhost:3000",
              "http://localhost:8080",
              "http://52.79.210.53:8080",
              "http://13.125.222.242:38253",
              "http://13.125.233.78:80",
              "https://api.aone-ivis.com:8080",
              "https://auth.aone-ivis.com:38253",
            ],
          }
        );

        // 로컬스토리지버전
        await localStorage.setItem("access_Token", result.data.accessToken);
        setToken(jwt_decode(localStorage.getItem("access_Token")));

        // dispatch({
        //   type: "accessToken/set",
        //   accessToken: result.data.accessToken,
        // });  // redux 버전
        if (result.data.success) {
          const expireDate = new Date();
          // add 1 day to today
          expireDate.setDate(new Date(new Date().getDate() + 14));
          // window.sessionStorage.setItem("userName", x.data.name);
          // removeCookie("refreshToken");

          cookies.set("refreshToken", result.data.refreshToken, {
            httpOnly: true,
            domain: "localhost",
            expires: expireDate,
          });
          setLoginStatus(new Date());
          //history.push("/Search");

          console.log(result);
          if (result.data.authorities.includes("BOSU")) {
            window.location.replace("/preinspection");
          } else if (result.data.authorities.includes("AONEBMW")) {
            window.location.replace("/chart");
          } else {
            window.location.replace("/search");
          }

          // if (authState.includes("BMWDEALER")) {
          //   history.push("/search");
          // } else if (authState.includes("AONEBMW")) {
          //   history.push("/chart");
          // }
        } else {
          alert("로그인 정보를 확인해 주세요.");
        }
      } catch (e) {
        alert(e);
        alert("로그인 정보를 확인해 주세요.");
      } finally {
        setUserPW("");
      }
    } else {
      alert("로그인 정보를 확인해 주세요.");
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    loginHandler();
  };
  //삭제 API
  // const callData = () => {
  //   LogCurrentName();
  // };
  const onChange = (e) => {
    setUserID(e.target.value);
  };
  const onChangepw = (e) => {
    setUserPW(e.target.value);
  };
  //로그인 정보를 올리는 API
  // 2021/12/09 로그인 두번씩 되는 오류 있어서 조치
  // 1. OnEnter 관련 전부 제거
  // 2. submit 버튼 onClick 삭제
  return (
    <StyledDiv>
      <ContentDiv>
        <LoginFormDiv>
          <BackgroundedDiv id="LoginTop">
            <div>IVIS</div>
          </BackgroundedDiv>

          <FormDiv className="flex-column flex-1">
            <HeaderDiv className="flex-row-center">
              <span>Login</span>
            </HeaderDiv>

            <StyledForm onSubmit={onSubmit}>
              <TextFieldDiv>
                <input
                  id="loginID"
                  name="bmwID"
                  value={userID}
                  onChange={onChange}
                  // onKeyDown={onEnter}
                  required
                />
                <span></span>
                <label>Username</label>
              </TextFieldDiv>
              <TextFieldDiv>
                <input
                  id="loginPW"
                  type="password"
                  name="bmwPW"
                  value={userPW}
                  onChange={onChangepw}
                  // onKeyDown={onEnter}
                  required
                />
                <span></span>
                <label>Password</label>
              </TextFieldDiv>
              <input type="submit" value="로그인" />
              {/*
              회원가입 페이지 우선 주석처리 - 2021-12-08
              <a href="/RegisterPage">
                <input type="button" value="회원가입" />
              </a>
               */}
            </StyledForm>
          </FormDiv>
        </LoginFormDiv>
      </ContentDiv>
    </StyledDiv>
  );
};
const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(120deg, #2980b9, #8e44ad);
  width: 100vw;
  height: 87.4vh;
`;
const TextFieldDiv = styled.div`
  position: relative;
  border-bottom: 2px solid #adadad;
  margin: 40px 0;
  & input {
    width: 100%;
    padding: 0 5px;
    height: 20px;
    font-size: 1vw;
    border: none;
    background: none;
    outline: none;
  }
  & label {
    position: absolute;
    top: 20%;
    left: 5px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: clamp(0.8rem, 0.8vw, 2rem);
    pointer-events: none;
    transition: 0.5s;
  }
  & span::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 0;
    height: 2px;
    background: #2691d9;
    transition: 0.5s;
  }
  & input:focus ~ label,
  & input:valid ~ label {
    top: -15px;
    color: #2691d9;
  }
  & input:focus ~ span::before,
  & input:valid ~ span::before {
    width: 100%;
  }
`;

const HeaderDiv = styled.div`
  border-bottom: 1px solid silver;
  width: 100%;
  height: 10vh;
  font-size: 2vw;
  font-weight: 700;
`;
const LoginFormDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  height: 50vh;
  width: 70vw;
`;
const FormDiv = styled.div`
  overflow: hidden;
  background-color: white;
  gap: 5vh;
`;
const StyledForm = styled.form`
  padding: 0 5vw;
  box-sizing: border-box;
  & input[type="submit"],
  & a input[type="button"] {
    font-family: "NanumSquare";
    width: 100%;
    height: 50px;
    border: 1px solid;
    background: #2691d9;
    border-radius: 25px;
    font-size: clamp(1.2rem, 1vw, 2rem);
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    outline: none;
  }
  & input[type="submit"]:hover {
    border-color: #2691d9;
    transition: 0.5s;
  }
  & input[type="button"] {
    width: 100%;
    height: 50px;
    border: 1px solid;
    background: #2691d9;
    border-radius: 25px;
    font-size: 1vw;
    color: #e9f4fb;
    font-weight: 700;
    cursor: pointer;
    outline: none;
  }
  & input[type="button"]:hover {
    border-color: #2691d9;
    transition: 0.5s;
  }
`;
const StyledDiv = styled.div`
  width: 100vw;
  background: linear-gradient(120deg, #2980b9, #8e44ad);
  overflow: hidden;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const BackgroundedDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-image: url(${LoginImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 4vh;
  font-size: clamp(2rem, 4vw, 7rem);
  font-weight: 700;
  color: white;
  flex: 1;
  & div {
    position: relative;
    animation: ${FadeInDown} 3s;
  }
`;

export default LoginForm;
