import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import Button from "@mui/material/Button";
import { spinnerAtom } from "../../states/Atoms/StatusAtoms";
import { useRecoilState } from "recoil";
import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";

const MultilineTextFields = ({ year, month, setMultiData, SetVehicleData }) => {
  const [spinner, setSpinner] = useRecoilState(spinnerAtom);
  const [value, setValue] = useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  // console.log(
  //   value
  //     .split("\n")-+
  //     .map((x) => x.trim())
  //     .filter((x) => x)
  // );
  // console.log(value.replaceAll("\n", ",").replace(/([,])\1+/g, "$1"));

  const multiSearchHandler = async (e) => {
    e.preventDefault();
    try {
      setSpinner(true);
      const result = await IVISAxios.get("/indvreduction", {
        params: {
          vin: value.replaceAll("\n", ",").replace(/([,])\1+/g, "$1"),
        },
      });
      SetVehicleData(result.data);
    } catch (e) {
      console.log(e);
    } finally {
      //스피너
      setSpinner(false);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "stretch", gap: 1 }}>
      <TextField
        id="outlined-multiline-flexible"
        placeholder="다수 차대번호 입력 / 콤마(,) 또는 줄바꿈 구분 가능"
        multiline
        sx={{ flex: 1, background: "white" }}
        value={value}
        onChange={handleChange}
      />
      <Button variant="outlined" onClick={multiSearchHandler}>
        <SearchIcon />
      </Button>
    </Box>
  );
};
export default MultilineTextFields;
