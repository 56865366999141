import Box from "@mui/material/Box";
import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextFiled from "@mui/material/TextField";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import Button from "@mui/material/Button";
import styled from "styled-components";
import SaveIcon from "@mui/icons-material/Save";
import { authoritiesAtom } from "../../states/Atoms/AuthAtoms";
import { useRecoilValue } from "recoil";

const ActivityCheck = ({ HistoryHandler, vehicleData }) => {
  //체크상태
  const [checked1, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const authState = useRecoilValue(authoritiesAtom);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChange2 = (event) => {
    setChecked2(event.target.checked);
  };
  const handleChange3 = (event) => {
    setChecked3(event.target.checked);
  };
  const handleChange4 = (event) => {
    setChecked4(event.target.checked);
  };

  // 텍스트 박스
  const [textBox, setTextBox] = useState("");
  const onChangeText = (e) => {
    setTextBox(e.target.value);
  };

  // 업로드 핸들러
  const CheckBoxHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      console.log("이벤트 시작");
      const result = await IVISAxios.post(
        "/applhistory",
        new URLSearchParams({
          vin: vehicleData.vin,
          errorType1: checked1,
          errorType2: checked2,
          errorType3: checked3,
          errorType4: checked4,
          reason: textBox,
          writtenBy: authState.includes("AONEBMW")
            ? "A"
            : authState.includes("BMWDEALER")
            ? "D"
            : "O",
        })
      );
      HistoryHandler();
    } catch (error) {
      console.log(error);
    } finally {
      setTextBox("");
    }
  };

  return (
    <form onSubmit={CheckBoxHandler}>
      <div>
        {authState.includes("AONEBMW") && (
          <InlineDiv>
            <Checkbox
              checked={checked1}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            서류누락
            <Checkbox
              checked={checked2}
              onChange={handleChange2}
              inputProps={{ "aria-label": "controlled" }}
            />
            오타
            <Checkbox
              checked={checked3}
              onChange={handleChange3}
              inputProps={{ "aria-label": "controlled" }}
            />
            정보오류
            <Checkbox
              checked={checked4}
              onChange={handleChange4}
              inputProps={{ "aria-label": "controlled" }}
            />
            기타
            {/* <Button variant="contained" onClick={CheckBoxHandler}>
              확인완료
            </Button> */}
          </InlineDiv>
        )}
        <div />
        <Box sx={{ display: "flex", marginY: 2 }}>
          <TextFiled
            onChange={onChangeText}
            sx={{ backgroundColor: "#fff", borderRadius: "5", width: 1 }}
            value={textBox}
          />
          <Button
            variant="contained"
            onClick={CheckBoxHandler}
            disabled={!textBox.trim()}
          >
            저장
          </Button>
        </Box>
      </div>
    </form>
  );
};
const InlineDiv = styled.div`
  display: inline;
`;
export default ActivityCheck;
