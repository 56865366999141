import { useRecoilState } from "recoil";
import { authoritiesAtom } from "../states/Atoms/AuthAtoms";
import { useEffect } from "react";
import { IVISAxios } from "../Modules/Axios/IVISAxios";
import { loginStatusAtom } from "../states/Atoms/AuthAtoms";
const Logout = () => {
  const [authState, setAuthState] = useRecoilState(authoritiesAtom);
  const [loginStatus, setLoginStatus] = useRecoilState(loginStatusAtom);
  useEffect(() => {
    async function Logout() {
      await IVISAxios.get("/logout");
    }
    Logout();
    localStorage.setItem("access_Token", null);
    setLoginStatus(null);
    setAuthState([]);
  }, []);

  return <>로그아웃 중</>;
};

export default Logout;
