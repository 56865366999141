import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Cookies from "universal-cookie";
import MUIAlert from "../Alert/MUIAlert";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import { spinnerAtom, toastAtom } from "../../states/Atoms/StatusAtoms";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";

const cookies = new Cookies();
const SearchForm = ({ inquiryHandler, setNumber }) => {
  const [snackState, setSnackState] = useRecoilState(toastAtom);
  //Change Number

  const onChangeNumber = (e) => {
    setNumber(e.target.value);
  };

  return (
    <Box>
      <form onSubmit={inquiryHandler}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "stretch",
              flex: 1,
              m: { xs: 0, sm: 5 },
              px: { xs: 0, sm: 5 },
              borderRadius: 5,
              background: "#ffffff",
              boxShadow: "20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff",
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                flex: 1,
                p: 3,
                display: "flex",
                alignItems: "stretch",
              }}
            >
              {/* <FormBox>
                <h3>차대번호 조회</h3>
              </FormBox> */}
              <TextField
                type="text"
                label="차대번호"
                onChange={onChangeNumber}
                sx={{ background: "white", flex: 1 }}
              />

              <Button variant="outlined" onClick={inquiryHandler}>
                <SearchIcon />
              </Button>
              {/* <Button
                variant="outlined"
                color="error"
                onClick={refreshHandler}
                style={{
                  backgroundColor: "#fff",
                  padding: "15px",
                }}
              >
                <SearchIcon />
              </Button> */}
            </Box>
          </Grid>
          {/* <Grid
            item
            sx={{ textAlign: "right", display: { xs: "none", sm: "block" } }}
          >
            샘플차대번호
            <br /> WBA5V5100NFL60986
            <br /> WBA5V5103NFL66376
            <br /> WBA5V5103NFL66782
            <br /> WBA5V5104NFL67598
            <br /> WBA5V5105NFL68551
            <br /> WBA5V5107NFL72956
            <br /> WBACW2103N9J19196
          </Grid> */}
        </Grid>
      </form>
    </Box>
  );
};
export default SearchForm;

const FormBox = styled.div`
  align-items: center;
  color: black;
`;
