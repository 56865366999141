import { useEffect, useState } from "react";
import "./Hamburger.css";

const Hamburger = (props) => {
  const [folded, setToggle] = useState(props.toggle);

  useEffect(() => {
    setToggle(props.toggle);
  }, [props.toggle]);

  return (
    <div
      className="hamburger"
      onClick={() => {
        setToggle(!folded);
        props.toggleFunction(!folded);
      }}
    >
      <div className={folded ? "folded bar1" : "bar1"}></div>
      <div className={folded ? "folded bar2" : "bar2"}></div>
      <div className={folded ? "folded bar3" : "bar3"}></div>
    </div>
  );
};

export default Hamburger;
