import React, { useState, useEffect } from "react";
import NotAppliedComponent from "../../Card/NotAppliedComponent";
import AppliedComponent from "../../Card/AppliedComponent";
import SearchForm from "../../Search/SearchForm";
import { useRecoilValue } from "recoil";
import { authoritiesAtom } from "../../../states/Atoms/AuthAtoms";
import { spinnerAtom } from "../../../states/Atoms/StatusAtoms";
import { useRecoilState } from "recoil";
import { IVISAxios } from "../../../Modules/Axios/IVISAxios";
import MUIAlert from "../../Alert/MUIAlert";
import moment from "moment";
import Fade from "@mui/material/Fade";
import styled from "styled-components";
import { once } from "../../../utils/once";
// vin: url이나 props로 호출된 차대번호
// searchedvin: 현재 검색된 차대번호
const IndvRefund = ({ vin }) => {
  const [currentvin, setCurrentvin] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [refundExpected, setRefundExpected] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [spinner, setSpinner] = useRecoilState(spinnerAtom);
  const [errorMessage, setErrorMessage] = useState("");

  const authState = useRecoilValue(authoritiesAtom);

  useEffect(() => {
    (async () => {
      if (vin) {
        setCurrentvin(vin);
        await inquiry(vin);
      }
    })();
    return () => {};
  }, []);

  //미신청건 조회 state
  const [searchApplied, setSearchApplied] = useState(false);

  //차대번호 조회 Handler

  const inquiryHandler = async (e) => {
    if (e) e.preventDefault();

    await inquiry(currentvin);
  };

  //미신청시 버튼 클릭시에 type담는 state
  const [refundType, setRefundType] = useState("");

  //파일 있는지 없는지 확인하는 함수

  const [isUploaded, setIsUploaded] = useState();

  const inquiryAttachedFiles = async (vin) => {
    try {
      const result = await IVISAxios.get("/attached", {
        params: { vin: vin },
      });
      if (result.data.success) {
        setIsUploaded(result.data.result);
      } else {
        setIsUploaded({});
      }
    } catch (e) {
      console.error(e);
    }
  };

  //History 불러오는 핸들러
  const [active, setactive] = useState({});

  const GetHistory = async (vin) => {
    try {
      const result = await IVISAxios.get("/applhistory", {
        params: {
          vin: vin,
        },
      });
      setactive(result);
    } catch (e) {
      console.error(e);
    }
  };
  const HistoryHandler = async (e) => {
    await GetHistory(currentvin);
  };

  //스낵바 테스트
  const [snackstate, snackSetState] = React.useState({
    open: false,
    Transition: Fade,
  });

  const inquiry = async (vin) => {
    try {
      setSpinner(true);
      const appliedResult = await IVISAxios.get(`/indvreduction/${vin}`);

      setIsApplied(appliedResult && appliedResult.data.success);
      //1. 신청 o 딜러사(사업자번호) 불일치 === 신청된 차대번호 입니다.
      //2. 신청 o 딜러사 일치 === notapplied.
      //3. 미신청 백지상태.
      // TODO: 많은 호출 개선 필요.
      if (appliedResult && appliedResult.data.success) {
        setVehicleData(appliedResult.data.data);
        await GetHistory(vin);
        inquiryAttachedFiles(vin);
        setIsSearched(true);
      } else if (
        appliedResult &&
        !appliedResult.data.success &&
        appliedResult.data.code === "E01"
      ) {
        console.log("체크2");
        setIsSearched(false);
        setOpen(true);
        setErrorMessage("타사 신청건입니다.");
        return;
      } else {
        console.log("체크3");
        const result = await IVISAxios.get(`/bmwvehicles/${vin}`);
        if (result) {
          let appliedResultData = { ...result.data };
          if (!appliedResultData.aprvTime) {
            setIsSearched(false);
            setOpen(true);
            setErrorMessage("통관되지 않은 차대번호입니다. 다시 조회해주세요.");
          }
          appliedResultData.aprvTime = moment(
            appliedResultData.aprvTime
          ).format("YYYY/MM/DD");
          appliedResultData.Vat = appliedResultData.Vat.toLocaleString("ko-KR");
          appliedResultData.IndvTax =
            appliedResultData.IndvTax.toLocaleString("ko-KR");
          appliedResultData.EduTax =
            appliedResultData.EduTax.toLocaleString("ko-KR");
          appliedResultData.TotalTax =
            appliedResultData.TotalTax.toLocaleString("ko-KR");
          setIsSearched(true);
          setVehicleData(appliedResultData);
        } else {
          console.log("체크4");
          setIsSearched(false);
          return;
        }
        console.log("체크5");
        setSearchApplied(true);
        setRefundType(null);
        setRefundExpected({});
        setSnackOpen(true);
      }
    } catch (e) {
      console.error(e);
      // 차대번호 아닌 오류라는 별도 처리가 필요
    } finally {
      //스피너
      setSpinner(false);
    }
  };

  //엘럿 컨트롤
  const [open, setOpen] = useState(false);
  const onClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //스낵바 상탯값
  const [snackOpen, setSnackOpen] = useState(false);
  return (
    <div className="container">
      <MUIAlert
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        text={errorMessage}
      />
      {!vin ? (
        <SearchForm
          setVehicleData={setVehicleData}
          vehicleData={vehicleData}
          setSearchApplied={setSearchApplied}
          isApplied={isApplied}
          setIsApplied={setIsApplied}
          inquiryHandler={inquiryHandler}
          setNumber={setCurrentvin}
        />
      ) : null}
      {isSearched && isApplied ? (
        <div>
          <IVISHeader>
            조회결과 : 신청건 / 마감기한 : {vehicleData?.deadLine}
          </IVISHeader>
          {/* <button onClick={onClickOpen}>오픈버튼</button>
          <MUIAlert open={open} handleClose={handleClose} /> */}
          <AppliedComponent
            vehicleData={vehicleData}
            isUploaded={isUploaded}
            inquiryAttachedFiles={inquiryAttachedFiles}
            inquiryHandler={inquiryHandler}
            HistoryHandler={HistoryHandler}
            active={active}
          />
        </div>
      ) : isSearched && !isApplied ? (
        <div>
          <IVISHeader>조회결과 : 미신청건</IVISHeader>
          <NotAppliedComponent
            vehicleData={vehicleData}
            searchApplied={searchApplied}
            inquiryHandler={inquiryHandler}
            refundType={refundType}
            setRefundType={setRefundType}
            refundExpected={refundExpected}
            setRefundExpected={setRefundExpected}
          />
        </div>
      ) : null}
      <br /> <br /> <br /> <br />
    </div>
  );
};

const IVISHeader = styled.div`
  font-size: 20px;
  margin: 20px;
`;

export default IndvRefund;
