import React, { useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import { authoritiesAtom } from "../../states/Atoms/AuthAtoms";
import { useRecoilValue } from "recoil";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import Icon from "@mui/material/Icon";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";

const ActivityHistory = ({ active, setIsConfirmed, HistoryHandler }) => {
  const authState = useRecoilValue(authoritiesAtom);
  useEffect(() => {
    // 2021/12/13 댓글 기능 변경으로 인해 컨펌 여부 언체크
    // setIsConfirmed(
    //   active &&
    //     active.data &&
    //     active.data[active.data.length - 1] &&
    //     active.data[active.data.length - 1].isConfirmed
    // );
  }, [active]);

  //확인완료를 누를 권한이 있는지 판별 하는 함수
  const isConfirmable = (writtenBy) => {
    if (authState.includes("AONEBMW")) {
      return writtenBy === "D";
      // 에이원
    } else if (authState.includes("BMWDEALER")) {
      //딜러
      return writtenBy === "A";
    } else {
      return false;
    }
  };

  //컨펌핸들러
  const ConfirmHandler = async (id) => {
    try {
      const result = await IVISAxios.patch(
        "/applhistory/confirm",
        new URLSearchParams({
          id: id,
        })
      );
      console.log(result);
      HistoryHandler();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Box sx={{ marginBottom: 5 }}>
        <Table size="small">
          <TableHead sx={{ background: "#ddd" }}>
            <TableRow>
              <TableCell align="center">코멘트</TableCell>
              <TableCell align="center">등록자</TableCell>
              <TableCell
                sx={{ display: { xs: "none", sm: "table-cell" } }}
                align="center"
              >
                오류내용
              </TableCell>
              <TableCell
                sx={{
                  display: { xs: "none", sm: "table-cell" },
                }}
                align="center"
              >
                등록시간
              </TableCell>
              <TableCell
                sx={{
                  display: { xs: "none", sm: "table-cell" },
                }}
                align="center"
              >
                확인
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {active &&
              active.data &&
              active.data.map((x) => (
                <TableRow hover key={x.id}>
                  <TableCell size="small" sx={{ width: 1 }}>
                    {x.reason ? x.reason : ""}
                  </TableCell>
                  <TableCell sx={{ minWidth: 100 }}>{x.name}</TableCell>
                  <TableCell
                    sx={{
                      minWidth: 100,
                      display: { xs: "none", sm: "table-cell" },
                    }}
                    align="center"
                  >
                    {x.errorType1 ? (
                      <Chip
                        color="warning"
                        label="서류누락"
                        size="small"
                        sx={{ margin: 0.3 }}
                      />
                    ) : null}
                    {x.errorType2 ? (
                      <Chip
                        color="warning"
                        label="오타"
                        size="small"
                        sx={{ margin: 0.3 }}
                      />
                    ) : null}
                    {x.errorType3 ? (
                      <Chip
                        color="warning"
                        label="정보오류"
                        size="small"
                        sx={{ margin: 0.3 }}
                      />
                    ) : null}
                    {x.errorType4 ? (
                      <Chip color="warning" label="기타" size="small" />
                    ) : null}
                  </TableCell>
                  {/* <TableCell align="center">
                    <TextCenter>{x.errorType1 ? "v" : ""} </TextCenter>
                  </TableCell>
                  <TableCell align="center">
                    <TextCenter>{x.errorType2 ? "v" : ""} </TextCenter>
                  </TableCell>
                  <TableCell align="center">
                    <TextCenter>{x.errorType3 ? "v" : ""} </TextCenter>
                  </TableCell>
                  <TableCell align="center">
                    <TextCenter>{x.errorType4 ? "v" : ""} </TextCenter>
                  </TableCell> */}

                  {/* <TableCell align="center">
                    <Chip
                      color={x.isConfirmed ? "primary" : "warning"}
                      label={x.isConfirmed ? "Done" : "Fail"}
                      size="small"
                    />
                  </TableCell> */}
                  <TableCell
                    align="center"
                    sx={{
                      width: "auto",
                      display: { xs: "none", sm: "table-cell" },
                    }}
                  >
                    {moment(x.createdAt).format("YYYY/MM/DD HH:mm")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      width: "auto",
                      display: { xs: "none", sm: "table-cell" },
                    }}
                  >
                    {x.confirmAt ? (
                      <Box sx={{ flex: 1, textAlign: "center" }}>
                        <Tooltip
                          title={
                            x.writtenBy === "A"
                              ? x.confirmedBy +
                                "\n" +
                                moment(x.confirmAt).format("YYYY/MM/DD HH:mm")
                              : x.confirmedBy +
                                "\n" +
                                moment(x.confirmAt).format("YYYY/MM/DD HH:mm")
                          }
                        >
                          <IconButton sx={{ color: "#0a0" }}>
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : isConfirmable(x.writtenBy) ? (
                      <IconButton onClick={() => ConfirmHandler(x.id)}>
                        <IconButton sx={{ color: "#ddd" }}>
                          <CheckIcon />
                        </IconButton>
                      </IconButton>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default ActivityHistory;

const TextCenter = styled.div`
  text-align: center;
`;
