import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import { spinnerAtom } from "../../states/Atoms/StatusAtoms";
import { useRecoilState } from "recoil";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel } from "@mui/material";
const ChartComponent = ({
  SetVehicleData,
  setYear,
  setMonth,
  year,
  month,
  setType,
  type,
}) => {
  useEffect(() => {
    setYear(new Date().getFullYear().toString());
    setMonth((new Date().getMonth() + 1).toString());
  }, []);
  const [spinner, setSpinner] = useRecoilState(spinnerAtom);
  let years = [];
  for (let i = new Date().getFullYear(); i >= 2021; i--)
    years.push(i.toString());

  const months = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const InquiryApplicationHandler = async (e) => {
    try {
      setSpinner(true);
      const result = await IVISAxios.get(`/indvreduction`, {
        params: {
          year: year,
          month: month,
          // type: type,
        },
      });

      SetVehicleData(result.data);
    } catch (e) {
      console.log(e);
    } finally {
      //스피너
      setSpinner(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          flex: 1,
          gap: 1,
        }}
      >
        <FormControl>
          <InputLabel>조회 기준</InputLabel>
          <Select value={type} onChange={handleChangeType} label="조회 기준">
            <MenuItem value={"A"}>신청일</MenuItem>
            <MenuItem value={"S"}>접수일</MenuItem>
            <MenuItem value={"C"}>승인일</MenuItem>
            <MenuItem value={"D"}>지급일</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          value={year}
          onChange={(event, newValue) => {
            setYear(newValue);
          }}
          id="controllable-states-demo"
          options={years}
          sx={{ background: "white", width: 200 }}
          renderInput={(params) => <TextField {...params} label="year" />}
        />
        <Autocomplete
          value={month}
          onChange={(event, newValue) => {
            setMonth(newValue);
          }}
          id="controllable-states-demo"
          options={months}
          sx={{ background: "white", width: 200 }}
          renderInput={(params) => <TextField {...params} label="month" />}
        />
        <Button variant="outlined" onClick={InquiryApplicationHandler}>
          <SearchIcon />
        </Button>
      </Box>
    </Box>
  );
};
export default ChartComponent;
