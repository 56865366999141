import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./Theme/Theme";
import { Provider } from "react-redux";
import store, { persistor } from "./states/Stores/store";
import { PersistGate } from "redux-persist/es/integration/react";
import HttpsRedirect from "react-https-redirect";
ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect>
      <Provider store={store}>
        <PersistGate
          // loading={<Loading />}
          loading={null}
          // onBeforeLift={onBeforeLift}
          persistor={persistor}
        >
          <RecoilRoot>
            <ThemeProvider theme={Theme}>
              <App />
            </ThemeProvider>
          </RecoilRoot>
        </PersistGate>
      </Provider>
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById("root")
);
