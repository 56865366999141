import { atom } from "recoil";

export const userAtom = atom({
  key: "userInfo",
  default: {},
});

export const authoritiesAtom = atom({
  key: "userAuthorities",
  default: [],
});

export const loginStatusAtom = atom({
  key: "loginStatus",
  default: null,
});

export const brandAtom = atom({
  key: "brand",
  default: [],
});

export const isLoginAtom = atom({
  key: "#isLoginAtom",
  default: false,
});
export const tokenAtom = atom({
  key: "#tokenAtom",
  default: "",
});
