import React, { useState } from "react";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const RepInfo = ({ vehicleData, inquiryHandler }) => {
  const onChangeappNumber = (e) => {
    setRepData((prevState) => ({ ...prevState, appNumber: e.target.value }));
  };
  const onChangeexpectedAppliedAt = (e) => {
    setRepData((prevState) => ({
      ...prevState,
      expectedAppliedAt: e.target.value,
    }));
  };

  // 정보 담는 state
  const [repData, setRepData] = useState({
    appNumber: vehicleData.appNumber,
    expectedAppliedAt: vehicleData.expectedAppliedAt,
  });

  const applicationHandler = async (e) => {
    try {
      const result = await IVISAxios.patch(
        "/rep",
        new URLSearchParams({
          vin: vehicleData.vin,
          repData: JSON.stringify(repData),
        })
      );
      alert("등록 완료되었습니다.");
      inquiryHandler();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="접수번호"
            onChange={onChangeappNumber}
            value={repData.appNumber}
            sx={{ width: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="date"
            type="date"
            label="접수일자"
            sx={{ width: 220, flex: 1, alignSelf: "center" }}
            onChange={onChangeexpectedAppliedAt}
            value={repData.expectedAppliedAt}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        onClick={applicationHandler}
        // disabled={!type}
      >
        접수 정보 등록
      </Button>
    </Box>
  );
};

export default RepInfo;
