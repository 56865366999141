import "./Footer.css";
import "./mFooter.css";
import styled from "styled-components";

const Footer = () => {
  return (
    <footer>
      <div className="container flex-row">
        <FooterBox>
          <h3 className="item">관세법인 에이원</h3>
        </FooterBox>
        <div className="flex-column">
          <p>
            <span className="item">
              서울특별시 강남구 언주로 703 3층 (LX한국국토정보공사, 논현동)
            </span>
          </p>
          <p>
            <span>전진아 팀장</span>
            <span>02-2017-3368</span>
            <span>신아리아 관세사</span>
            <span>02-2017-3391</span>
          </p>
          <p></p>
        </div>

        {/* <FooterBox>
          <h3>CONTACT POINT</h3>
        </FooterBox>

        <div className="flex-column">
          <p>
            <span className="item">전진아 관세사 02-2017-3368</span>
          </p>
          <p>
            <span className="item">신아리아 관세사 02-2017-3391</span>
          </p>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;

const FooterBox = styled.div`
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  /* margin-top: 10px;
  margin-bottom: 10px; */

  display: inline-block;
  white-space: nowrap;
`;
