import Box from "@mui/material/Box";
import FileUploader from "../FileUpload/FileUploader";
const IndvFileUploader = ({
  title,
  type,
  vin,
  inquiryAttachedFiles,
  isUploaded,
  isConfirmed,
  deleteAuthority,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        p: 2,
        borderRadius: "10px",
        background: "#ffffff",
        boxShadow:
          "14px 14px 23px #e6e6e6,             -14px -14px 23px #ffffff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          background: "#eee",
          borderRadius: 2,
          p: 1,
        }}
      >
        {title}
      </Box>
      <Box sx={{ display: "flex", flex: 1, justifyContent: "center" }}>
        <FileUploader
          deleteAuthority={deleteAuthority}
          type={type}
          vin={vin}
          inquiryAttachedFiles={inquiryAttachedFiles}
          isUploaded={isUploaded}
          isConfirmed={isConfirmed}
        />
      </Box>
    </Box>
  );
};
export default IndvFileUploader;
