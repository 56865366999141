import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import useGetIndvreduction from "../../hooks/api/useGetIndvreduction";
import { FormControl, InputLabel } from "@mui/material";
import { CRN_MAP } from "../../utils/crn-mapper";

const SearchRange = ({ setType, type }) => {
  const {
    InquiryApplicationHandler,
    start,
    finish,
    setStart,
    setFinish,
    crn,
    setCrn,
  } = useGetIndvreduction();

  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const onChangeStart = (e) => {
    setStart(e.target.value);
  };
  const onChangeFinish = (e) => {
    setFinish(e.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        flex: 1,
        gap: 1,
      }}
    >
      <FormControl>
        <InputLabel>조회 기준</InputLabel>
        <Select value={type} onChange={handleChangeType} label="조회 기준">
          <MenuItem value={"A"}>신청일</MenuItem>
          <MenuItem value={"S"}>접수일</MenuItem>
          <MenuItem value={"C"}>승인일</MenuItem>
          <MenuItem value={"D"}>지급일</MenuItem>
        </Select>
      </FormControl>

      <TextField
        id="date"
        label="Start"
        type="date"
        value={start}
        sx={{ width: 220 }}
        onChange={onChangeStart}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="date"
        label="Finish"
        type="date"
        sx={{ width: 220 }}
        value={finish}
        onChange={onChangeFinish}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControl sx={{ minWidth: 100 }}>
        <InputLabel>딜러사</InputLabel>
        <Select
          value={crn}
          onChange={(e) => setCrn(e.target.value)}
          label="딜러사"
        >
          <MenuItem value="all">전체</MenuItem>
          {Array.from(CRN_MAP.keys(), (crn) => (
            <MenuItem key={crn} value={crn}>
              {CRN_MAP.get(crn)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="outlined" onClick={InquiryApplicationHandler}>
        <SearchIcon />
      </Button>
    </Box>
  );
};
export default SearchRange;
