import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Box from "@mui/material/Box";

import ExcelDownloadCard from "../Component/Excel/ExcelDownloadCard";
import WorksComponent from "../Component/Works/WorksComponent";
import { tokenAtom } from "../states/Atoms/AuthAtoms";

const WorksPage = () => {
  const history = useHistory();
  const token = useRecoilValue(tokenAtom);

  useEffect(() => {
    if (token) {
      if (token.id !== "master") {
        history.push("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.id]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "30px",
        height: "100%",
        p: 10,
      }}
    >
      <WorksComponent />

      <ExcelDownloadCard
        title="TCMS 인보이스 FTA 적용 데이터 다운로드"
        url="/excel/fta/apply"
        filename="FTA적용현황"
      />
      <ExcelDownloadCard
        title="RDC 단가차이 내역 다운로드"
        url="/excel/rdc/subtract"
        filename="RDC_Subtract"
      />
      <ExcelDownloadCard
        title="TCMS New 자재 리스트 다운로드"
        url="/excel/tcms/new"
        filename="TCMS_New"
      />
    </Box>
  );
};

export default WorksPage;
