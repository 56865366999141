import { atom } from "recoil";

// export const userAtom = atom({
//   key: "userInfo",
//   default: {},
// });

export const spinnerAtom = atom({
  key: "spinnerArray",
  default: false,
});

export const MenuStatusAtom = atom({
  key: "menu",
  default: null,
});

export const toastAtom = atom({
  key: "key",
  default: null,
});

export const snackbarAtom = atom({
  key: "#snackbarState",
  default: {
    isOpen: false,
    text: "",
    status: "success",
  },
});

export const modalAtom = atom({
  key: "#modalState",
  default: false,
});
