import { keyframes } from "styled-components";
export const FadeInDown = keyframes`
0% {
  opacity: 0;
  top: -50px;
}
100% {
  opacity: 1;
  top: 0px;
}
`;