import moment from "moment";
import { v4 as uuid } from "uuid";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const LatestComments = ({ unreadData }) => {
  return (
    <Card>
      <CardHeader title="Unread Comments" />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>차대번호</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell>등록자</TableCell>
                <TableCell align="center">오류내용</TableCell>
                <TableCell>등록시간</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {unreadData &&
                unreadData.data &&
                unreadData.data.map((x) => (
                  <TableRow hover key={x.id}>
                    <TableCell>
                      <a href={`/search/${x.vin}`}>{x.vin}</a>
                    </TableCell>
                    <TableCell>{x.reason}</TableCell>
                    <TableCell>{x.name}</TableCell>
                    <TableCell sx={{ minWidth: 100 }} align="center">
                      {x.errorType1 ? (
                        <Chip
                          color="warning"
                          label="서류누락"
                          size="small"
                          sx={{ margin: 0.3 }}
                        />
                      ) : null}
                      {x.errorType2 ? (
                        <Chip
                          color="warning"
                          label="오타"
                          size="small"
                          sx={{ margin: 0.3 }}
                        />
                      ) : null}
                      {x.errorType3 ? (
                        <Chip
                          color="warning"
                          label="정보오류"
                          size="small"
                          sx={{ margin: 0.3 }}
                        />
                      ) : null}
                      {x.errorType4 ? (
                        <Chip color="warning" label="기타" size="small" />
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {moment(x.createdAt).format("YYYY/MM/DD")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

export default LatestComments;
