import React from "react";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";

const MUIAlert = ({ open, handleClose, text }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Alert
          variant="outlined"
          severity="error"
          onClick={() => {
            handleClose(false);
          }}
        >
          {text}
        </Alert>
      </Dialog>
    </div>
  );
};
export default MUIAlert;
