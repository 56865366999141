import React, { useState } from "react";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import styled from "styled-components";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Radio from "@mui/material/Radio";
import { fontFamily } from "@mui/system";

const Register = () => {
  const history = useHistory();

  //유저 정보 담기
  const [userNewPWCheck, setUserNewPWCheck] = useState("");
  const [userPWError, setUserPWError] = useState(false);
  const [userPW, setUserPW] = useState("");
  const [userNewPW, setUserNewPW] = useState("");

  console.log(userPW);
  console.log(userNewPW);

  //현재 비밀번호가 맞는지 체크
  const onChangepw = (e) => {
    setUserPW(e.target.value);
  };

  const onChangeNewpw = (e) => {
    setUserPWError(e.target.value !== userNewPWCheck);
    setUserNewPW(e.target.value);
  };

  const onChangePasswordChk = (e) => {
    //비밀번호를 입력할때마다 password 를 검증하는 함수
    setUserPWError(e.target.value !== userNewPW);
    setUserNewPWCheck(e.target.value);
  };

  //변경하는 API
  const registerHandler = async (e) => {
    if (e) {
      e.preventDefault();
    }
    console.log("비밀번호 변경 시작");
    try {
      const result = await IVISAxios.post(
        "/password",
        new URLSearchParams({
          password: userPW,
          newPassword: userNewPW,
        })
      );
      if (result) {
        alert("비밀번호 변경 완료");
      } else {
        alert("비밀번호 변경 실패");
      }
      // window.location.replace("/chart");
    } catch (e) {
      alert("비밀번호 변경 실패");
      console.log("비밀번호 변경 실패");
      console.log(e);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <form onSubmit={registerHandler}>
            <Formbox>
              <Box sx={{ mb: 3 }}>
                <Typography color="textPrimary" variant="h2">
                  Change Password
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Aone-Customs
                </Typography>
              </Box>
            </Formbox>
            {/* 
            2022-01-13 type="password" 시에 글씨가 처리되지 않아 삭제
            <TextField
              fullWidth
              label="현재 비밀번호"
              type="Password"
              margin="normal"
              variant="outlined"
              onChange={onChangepw}
              style={{ font: "initial" }}
            />
            <TextField
              fullWidth
              label="새 비밀번호"
              type="Password"
              margin="normal"
              variant="outlined"
              onChange={onChangeNewpw}
            />
            <TextField
              fullWidth
              value={userNewPWCheck}
              label="새 비밀번호 확인"
              type="Password"
              margin="normal"
              variant="outlined"
              onChange={onChangePasswordChk}
            /> */}

            <TextFieldDiv>
              <input
                fullWidth
                type="Password"
                margin="normal"
                variant="outlined"
                onChange={onChangepw}
                required
              />
              <span></span>
              <label>현재 비밀번호</label>
            </TextFieldDiv>

            <TextFieldDiv>
              <input
                type="Password"
                margin="normal"
                variant="outlined"
                onChange={onChangeNewpw}
                required
              />
              <span></span>
              <label>새 비밀번호</label>
            </TextFieldDiv>
            <TextFieldDiv>
              <input
                value={userNewPWCheck}
                type="Password"
                margin="normal"
                variant="outlined"
                onChange={onChangePasswordChk}
                required
              />
              <span></span>
              <label>새 비밀번호 확인</label>
            </TextFieldDiv>

            {userPWError ? (
              <div style={{ color: "red" }}>비밀번호가 일치하지 않습니다.</div>
            ) : null}
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={!userNewPW || userPWError}
              >
                비밀번호 변경
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Register;

const Formbox = styled.div`
  margin-top: 30px;
`;
const FontBox = styled.div`
  font-family: "돋움";
`;

const Input = styled.input`
  width: 300px;
  height: 50px;
`;

const TextFieldDiv = styled.div`
  position: relative;
  border-bottom: 2px solid #adadad;
  margin: 40px 0;
  & input {
    width: 100%;
    padding: 0 5px;
    height: 20px;
    font-size: 1vw;
    border: none;
    background: none;
    outline: none;
  }
  & label {
    position: absolute;
    top: 20%;
    left: 5px;
    color: #adadad;
    transform: translateY(-50%);
    font-size: clamp(0.8rem, 0.8vw, 2rem);
    pointer-events: none;
    transition: 0.5s;
  }
  & span::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 0;
    height: 2px;
    background: #2691d9;
    transition: 0.5s;
  }
  & input:focus ~ label,
  & input:valid ~ label {
    top: -15px;
    color: #2691d9;
  }
  & input:focus ~ span::before,
  & input:valid ~ span::before {
    width: 100%;
  }
`;
