import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

const DataTable = ({ selectedData, radioType }) => {
  const columns = [
    { field: "invNo", headerName: "인보이스번호", width: 140 },
    { field: "itemCode", headerName: "제품코드", width: 140 },
    { field: "desc", headerName: "제품명", width: 140 },
    { field: "qty", headerName: "수량", width: 70 },
    {
      field: "result",
      headerName: "인증여부",
      width: 140,
    },
    { field: "components", headerName: "성분", width: 70 },
    { field: "producedDateInfo", headerName: "제조연월", width: 140 },
    { field: "remark", headerName: "마스터비고", width: 600 },
  ];
  return (
    <div style={{ height: 1000, width: "100%" }}>
      <DataGrid
        rows={
          radioType === "인증"
            ? selectedData.filter((x) => x.result)
            : selectedData
        }
        // rows={selectedData}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[50]}
      />
    </div>
  );
};

export default DataTable;
