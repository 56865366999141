import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
const steps = ["제출", "승인", "지급"];
const Stepbar = ({ vehicleData }) => {
  console.log(vehicleData);
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={
          vehicleData.status === "제출"
            ? 1
            : vehicleData.status === "승인"
            ? 2
            : vehicleData.status === "지급"
            ? 3
            : null
        }
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
export default Stepbar;
