import React from "react";
import Button from "@mui/material/Button";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";

const TypeSelectForm = ({
  vehicleData,
  inquiryHandler,
  buttonname,
  refundType,
  refundExpected
}) => {
  //최종 업로드 Axios API

  const applicationHandler = async (e) => {
    try {
      const result = await IVISAxios.post(
        "/indvreduction",
        new URLSearchParams({
          vin: vehicleData.vinNo,
          type: refundType,
          desc: vehicleData.description,
          modelYear: vehicleData.modelYear,
          dpVolume: vehicleData.dpVolume,
          importNumber: vehicleData.cusReportNumber,
          totalTaxationKRW: vehicleData.totalTaxationKRW,
          qty: 1,
          brand: vehicleData.brand,
          edu: vehicleData.EduTax.replace(/,/g, ""),
          indv: vehicleData.IndvTax.replace(/,/g, ""),
          reducedIndv: parseFloat(refundExpected.indvRefundExpected.replace(/,/g, "")),
          reducedEdu: parseFloat(refundExpected.eduRefundExpected.replace(/,/g, "")),
          //22-12-30 신청타입에 따라 계산되는 로직이 필요하다고함
          totalRefund:
            parseFloat(refundExpected.indvRefundExpected.replace(/,/g, ""))
            +
            parseFloat(refundExpected.eduRefundExpected.replace(/,/g, ""))

        })
      );
      console.log(result);
      console.log("이벤트 끝");
      alert("신청이 완료되었습니다.");
      inquiryHandler();
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <Button
        sx={{ width: 1 }}
        variant="contained"
        onClick={applicationHandler}
        disabled={!refundType}
      >
        {buttonname}
      </Button>
    </>
  );
};

export default TypeSelectForm;
