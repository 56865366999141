import MoonLoader from "react-spinners/MoonLoader";
import styled from "styled-components";
import { useState } from "react";
import { css } from "@emotion/react";

const Loading = () => {
    const [color, setColor] = useState("#abc");
  return (
    <LoaderDiv>
      <MoonLoader color={color} loading={true} css={override} size={150} />
    </LoaderDiv>
  );
};
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
const LoaderDiv = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 10000;
`;

export default Loading;
