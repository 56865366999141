import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import BuyerInfo from "../AONE/BuyerInfo";
const BuyerBox = ({ vehicleData, inquiryHandler }) => {
  return (
    <Box sx={{}}>
      <Card variant="outlined" sx={{ borderRadius: 3 }}>
        <CardHeader title="구매자정보" sx={{ backgroundColor: "#eee" }} />

        <CardContent>
          <BuyerInfo
            vehicleData={vehicleData}
            inquiryHandler={inquiryHandler}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
export default BuyerBox;
