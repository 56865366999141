import React, { useState } from "react";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
const ReadPage = () => {
  //등록
  const CreateComment = async () => {
    try {
      const result = await IVISAxios.post("./comment");
      console.log(result);
      ReadComment();
    } catch (e) {
      console.log(e);
    }
  };
  //읽기
  const ReadComment = async () => {
    try {
      const result = await IVISAxios.get("./comment", new URLSearchParams({}));
      console.log(result);
    } catch (e) {
      console.log(e);
    }
  };
  //수정

  //업데이트 모드 변경
  const UpdateMode = () => {
    setUpdateMode(false);
  };
  const [updateMode, setUpdateMode] = useState(true);

  const UpdateComment = async () => {
    try {
      const result = await IVISAxios.patch(
        "./comment",
        new URLSearchParams({})
      );
      console.log(result);
      ReadComment();
    } catch (e) {
      console.log(e);
    }
  };
  //삭제
  const DeleteComment = async () => {
    try {
      const result = await IVISAxios.delete(
        "./comment",
        new URLSearchParams({})
      );
      console.log(result);
      ReadComment();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <h3>댓글 컴포넌트</h3>
      댓글 작성창 <TextField />
      <Button>create</Button>
      <br />
      댓글 1. <TextField />
      <Button>update</Button>
      <Button>delete</Button> <br />
      댓글 2. <TextField />
      <Button>update</Button>
      <Button>delete</Button> <br />
      댓글 3. <TextField />
      <Button>update</Button>
      <Button>delete</Button>
    </>
  );
};

export default ReadPage;

const LayoutBox = styled.div`
  margin-top: 50px;
  margin-left: 200px;
  margin-right: 200px;
`;
