import React, { useState } from "react";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { IVISAxios } from "../../Modules/Axios/IVISAxios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const BuyerInfo = ({ vehicleData, inquiryHandler }) => {
  //최종 업로드 Axios API

  // const [type, setType] = useState(null);

  //구매자 정보 담는 state
  const [buyerData, setBuyerData] = useState({
    buyerName: vehicleData.buyerName,
    buyerCompanyName: vehicleData.buyerCompanyName,
    buyerAddress: vehicleData.buyerAddress,
    buyerPlace: vehicleData.buyerPlace,
    buyerCRN: vehicleData.buyerCRN,
    buyerSSNumber: vehicleData.buyerSSNumber,
    buyerTel: vehicleData.buyerTel,
    taxOffice: vehicleData.taxOffice,
    salesDate: vehicleData.salesDate,
    vehicleRegNo: vehicleData.vehicleRegNo,
    dg: vehicleData.dg,
  });

  const onChangeBuyerName = (e) => {
    setBuyerData((prevState) => ({ ...prevState, buyerName: e.target.value }));
  };
  const onChangeBuyerCompany = (e) => {
    setBuyerData((prevState) => ({
      ...prevState,
      buyerCompanyName: e.target.value,
    }));
  };

  const onChangeBuyerAddress = (e) => {
    setBuyerData((prevState) => ({
      ...prevState,
      buyerAddress: e.target.value,
    }));
  };

  const onChangeData4 = (e) => {
    setBuyerData((prevState) => ({ ...prevState, buyerPlace: e.target.value }));
  };

  const onChangeData5 = (e) => {
    setBuyerData((prevState) => ({ ...prevState, buyerCRN: e.target.value }));
  };

  const onChangeData6 = (e) => {
    setBuyerData((prevState) => ({
      ...prevState,
      buyerSSNumber: e.target.value,
    }));
  };

  const onChangeData7 = (e) => {
    setBuyerData((prevState) => ({ ...prevState, buyerTel: e.target.value }));
  };

  const onChangeData9 = (e) => {
    setBuyerData((prevState) => ({
      ...prevState,
      vehicleRegNo: e.target.value,
    }));
  };
  const onChangetaxOffice = (e) => {
    setBuyerData((prevState) => ({
      ...prevState,
      taxOffice: e.target.value,
    }));
  };

  const applicationHandler = async (e) => {
    try {
      const result = await IVISAxios.patch(
        "/buyer",
        new URLSearchParams({
          vin: vehicleData.vin,
          buyer: JSON.stringify(buyerData),
        })
      );
      alert("신청이 완료되었습니다.");
      inquiryHandler();
    } catch (error) {
      console.error(error);
    }
  };

  //신고자 정보 가져오는 핸들러 InquiryHandler에서 vehicle에조회! 동시조회 예정
  const getHandler = async (e) => {
    try {
      const result = await IVISAxios.get("/", {
        params: {
          vin: vehicleData.vinNo,
        },
      });
      setBuyerData(result.data);
    } catch (e) {
      console.error(e);
    }
  };

  // const typelist = ["장애인", "여객/렌터카"];
  const dg = ["1", "2", "3", "정도가 심한", "국가유공자", "고엽제"];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}>
      {/* <Autocomplete
          value={type}
          onChange={(event, newValue) => {
            setType(newValue);
            if (type !== "장애인") {
              dgreset();
            }
          }}
          disablePortal
          options={typelist}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="정보타입선택" />}
        /> */}
      {/* {type ? ( */}
      {/* {type === "장애인" ? ( */}
      <Box sx={{ margin: 1 }}>
        <Autocomplete
          value={buyerData.dg}
          onChange={(event, newValue) => {
            setBuyerData((prevState) => ({
              ...prevState,
              dg: newValue,
            }));
          }}
          disablePortal
          options={dg}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="장애등급(신청타입이 장애인인 경우)" />
          )}
        />
      </Box>
      {/* ) : null} */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="대표자명"
            onChange={onChangeBuyerName}
            value={buyerData.buyerName}
            sx={{ width: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="회사명"
            onChange={onChangeBuyerCompany}
            value={buyerData.buyerCompanyName}
            sx={{ width: 1 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <TextField
            label="주소"
            onChange={onChangeBuyerAddress}
            value={buyerData.buyerAddress}
            sx={{ width: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ display: "flex" }}>
            <TextField
              label="관할세무서"
              onChange={onChangetaxOffice}
              value={buyerData.taxOffice}
              sx={{ flex: 1 }}
            />
            <Typography sx={{ mx: 2, alignSelf: "center" }} variant="body1">
              세무서
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="판매 · 반출장소"
            onChange={onChangeData4}
            value={buyerData.buyerPlace}
            sx={{ width: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="사업자등록번호(숫자만입력)"
            onChange={onChangeData5}
            value={buyerData.buyerCRN}
            sx={{ width: 1 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="주민등록번호"
            onChange={onChangeData6}
            value={buyerData.buyerSSNumber}
            sx={{ width: 1 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="전화번호"
            onChange={onChangeData7}
            value={buyerData.buyerTel}
            sx={{ width: 1 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            label="자동차등록연월일"
            onChange={onChangeData8}
            value={buyerData.salesDate}
            sx={{ width: 1 }}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="자동차등록번호"
            onChange={onChangeData9}
            value={buyerData.vehicleRegNo}
            sx={{ width: 1 }}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        onClick={applicationHandler}
        // disabled={!type}
      >
        구매자 정보 등록
      </Button>
    </Box>
  );
};

export default BuyerInfo;
