import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { IVISAxios } from "../Modules/Axios/IVISAxios";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/Inbox";
import TitleComponent from "../Component/Title/TitleComponent";
const defaultDealerData = {
  repName: "",
  crn: "",
  companyName: "",
  ssNumber: "",
  companyAddress: "",
  salesAt: "",
  companyTel: "",
  bank: "",
  accntNo: "",
  id: 0,
};

const AdminPage = ({ vehicleData }) => {
  //초기 렌더링시 Data불러오기
  useEffect(() => {
    dealergetEffect();
    clientgetEffect();
  }, []);

  //BMW Data담는 state
  const [importerData, setImporterData] = useState({
    importerRep: "",
    importerCrn: "",
    importerName: "",
    importerAddr: "",
    bwCode: "",
  });

  //딜러 Text데이터를 담는 state OnChange 담는
  const [selectedDealerData, setSelectedDealerData] =
    useState(defaultDealerData);

  console.log("셀렉데이터", selectedDealerData.id);
  const onChangeData1 = (e) => {
    setImporterData((prevState) => ({
      ...prevState,
      importerRep: e.target.value,
    }));
  };
  const onChangeData2 = (e) => {
    setImporterData((prevState) => ({
      ...prevState,
      importerCrn: e.target.value,
    }));
  };
  const onChangeData3 = (e) => {
    setImporterData((prevState) => ({
      ...prevState,
      importerName: e.target.value,
    }));
  };
  const onChangeData4 = (e) => {
    setImporterData((prevState) => ({
      ...prevState,
      importerAddr: e.target.value,
    }));
  };
  const onChangeData5 = (e) => {
    setImporterData((prevState) => ({ ...prevState, bwCode: e.target.value }));
  };
  //BMW 데이터 수정 Handler
  const ClientPatchHandler = async (e) => {
    try {
      const result = IVISAxios.patch(
        "/client",
        new URLSearchParams({
          id: importerData.importerCrn,
          importerData: JSON.stringify(importerData),
        })
      );
      console.log(result);
      alert("업로드 완료");
    } catch (e) {
      console.log(e);
    }
  };

  const clientgetEffect = async (e) => {
    try {
      const result = await IVISAxios.get(`/client/2118608983`);
      setImporterData(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  //데이터 담는 set함수
  const DealerData1 = (e) => {
    setSelectedDealerData((prevState) => ({
      ...prevState,
      repName: e.target.value,
    }));
  };
  const DealerData2 = (e) => {
    setSelectedDealerData((prevState) => ({
      ...prevState,
      crn: e.target.value,
    }));
  };
  const DealerData3 = (e) => {
    setSelectedDealerData((prevState) => ({
      ...prevState,
      companyName: e.target.value,
    }));
  };
  const DealerData4 = (e) => {
    setSelectedDealerData((prevState) => ({
      ...prevState,
      ssNumber: e.target.value,
    }));
  };
  const DealerData5 = (e) => {
    setSelectedDealerData((prevState) => ({
      ...prevState,
      companyAddress: e.target.value,
    }));
  };

  const DealerData6 = (e) => {
    setSelectedDealerData((prevState) => ({
      ...prevState,
      salesAt: e.target.value,
    }));
  };

  const DealerData7 = (e) => {
    setSelectedDealerData((prevState) => ({
      ...prevState,
      companyTel: e.target.value,
    }));
  };

  const DealerData8 = (e) => {
    setSelectedDealerData((prevState) => ({
      ...prevState,
      bank: e.target.value,
    }));
  };
  const DealerData9 = (e) => {
    setSelectedDealerData((prevState) => ({
      ...prevState,
      accntNo: e.target.value,
    }));
  };

  ///////////////////////////////////////////////////////////////////

  //2021-12-29 생성 Handler 작업
  const [createID, setCreateID] = useState("");
  //Dealer 생성 ?

  ////////////////////////////////////////////////////////////////////
  //Dealer Patch 수정하기
  const dealerUpdateHandler = async (e) => {
    try {
      console.log(selectedDealerData.id);
      const result = await IVISAxios.patch(
        "/dealer",
        new URLSearchParams({
          id: selectedDealerData.id,
          dealerData: JSON.stringify(selectedDealerData),
        })
      );
      console.log(result);

      dealergetEffect();
      setSelectedDealerData(defaultDealerData);
      alert("수정 완료");
    } catch (e) {
      console.log(e);
    }
  };

  //dealer  get 최초 렌더링시 리스트 불러오는 핸들러
  const [dealers, setDealers] = useState([]);
  const dealergetEffect = async (e) => {
    try {
      const result = await IVISAxios.get(`/dealer`);
      console.log(result.data);
      setDealers(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  const dealerSelectionChangedHandler = async (e) => {
    try {
      console.log(e.target.parentNode.id);
      setSelectedDealerData(defaultDealerData);
      const selectedID = e.target.id ? e.target.id : e.target.parentNode.id;
      const result = await IVISAxios.get(`/dealer/${selectedID}`);
      setSelectedDealerData(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  //Dealer 데이터 삭제 핸들러
  const dealerDeleteHandler = async (e) => {
    try {
      const result = await IVISAxios.delete(
        `/dealer/${selectedDealerData.crn}`
      );
      console.log(result);
    } catch (e) {
      console.log(e);
    }
  };

  const dealerCreateHandler = async (e) => {
    try {
      const result = await IVISAxios.post("/dealer");
      console.log(result);
      dealergetEffect();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <TitleComponent Title="관리자 페이지" type="2" />
      <div className="container">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ display: "flex" }}>
              <Box>
                <h3>BMWKorea 전용 </h3>
                대표자명
                <br />
                <TextField
                  onChange={onChangeData1}
                  style={{
                    width: "20vw",
                  }}
                  value={importerData.importerRep}
                />
                <br />
                사업자등록번호
                <br />
                <TextField
                  onChange={onChangeData2}
                  style={{
                    width: "20vw",
                  }}
                  value={importerData.importerCrn}
                />
                <br />
                상호
                <br />
                <TextField
                  onChange={onChangeData3}
                  style={{
                    width: "20vw",
                  }}
                  value={importerData.importerName}
                />
                <br />
                주소
                <br />
                <TextField
                  onChange={onChangeData4}
                  style={{
                    width: "20vw",
                  }}
                  value={importerData.importerAddr}
                />
                <br />
                반출장소
                <br />
                <TextField
                  onChange={onChangeData5}
                  style={{
                    width: "20vw",
                  }}
                  value={importerData.bwCode}
                />
                <br />
                <Button
                  variant="contained"
                  color="success"
                  onClick={ClientPatchHandler}
                >
                  수정하기
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <h3>각 딜러사</h3>
            <Box>
              <List component="nav" aria-label="main mailbox folders">
                {dealers.map((x) => {
                  return (
                    <ListItemButton
                      id={x.id}
                      key={x.id}
                      onClick={dealerSelectionChangedHandler}
                    >
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText id={x.id}>{x.companyName}</ListItemText>
                    </ListItemButton>
                  );
                })}
              </List>
            </Box>

            {selectedDealerData.id ? (
              <>
                대표자
                <br />
                <TextField
                  onChange={DealerData1}
                  style={{
                    width: "20vw",
                  }}
                  value={selectedDealerData.repName}
                />
                <br />
                사업자등록번호
                <br />
                <TextField
                  onChange={DealerData2}
                  style={{
                    width: "20vw",
                  }}
                  value={selectedDealerData.crn}
                />
                <br />
                상호
                <br />
                <TextField
                  onChange={DealerData3}
                  style={{
                    width: "20vw",
                  }}
                  value={selectedDealerData.companyName}
                />
                <br />
                주민등록번호
                <br />
                <TextField
                  onChange={DealerData4}
                  style={{
                    width: "20vw",
                  }}
                  value={selectedDealerData.ssNumber}
                />
                <br />
                주소
                <br />
                <TextField
                  onChange={DealerData5}
                  style={{
                    width: "20vw",
                  }}
                  value={selectedDealerData.companyAddress}
                />
                <br />
                판매 · 반출장소
                <br />
                <TextField
                  onChange={DealerData6}
                  style={{
                    width: "20vw",
                  }}
                  value={selectedDealerData.salesAt}
                />
                <br />
                전화번호
                <br />
                <TextField
                  onChange={DealerData7}
                  style={{
                    width: "20vw",
                  }}
                  value={selectedDealerData.companyTel}
                />
                <br />
                은행
                <br />
                <TextField
                  onChange={DealerData8}
                  style={{
                    width: "20vw",
                  }}
                  value={selectedDealerData.bank}
                />
                <br />
                계좌번호
                <br />
                <TextField
                  onChange={DealerData9}
                  style={{
                    width: "20vw",
                  }}
                  value={selectedDealerData.accntNo}
                />
                <br />
                <Button
                  variant="contained"
                  color="success"
                  onClick={dealerUpdateHandler}
                >
                  수정하기
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={dealerCreateHandler}
                >
                  신규생성
                </Button>
                {/* <Buttono
              variant="contained"
              color="error"
              onClick={dealerDeleteHandler}
              >
              삭제하기
            </Button> */}
              </>
            ) : null}
          </Grid>
        </Grid>
      </div>
      <br /> <br /> <br /> <br /> <br />
    </>
  );
};

export default AdminPage;
